import axios from "axios";
import { API_URL } from "variables/constant";

export const getParam = (param) => {
    const hash = window.location.hash;
    const queryString = hash.substring(hash.indexOf('?') + 1);
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(param);
};

export const exportCSV = async (api, start, end, setLoading, params={}) => {
    try {
      setLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/export/${api}`, { startDate: start, endDate: end, ...params }, { withCredentials: true });
      setLoading(false)
  
      if (response.status === 200) {
        const { fileName } = response.data;
  
        // Trigger file download
        const link = document.createElement('a');
        link.href = `${process.env.REACT_APP_API_URL}/csvs/${fileName}`; // Assume files are served from the /downloads endpoint
        console.log(link.href)
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('Failed to export CSV file:', response.statusText);
      }
    } catch (error) {
      console.error('Error exporting CSV file:', error);
    }
  };

export const isMediaValid = (str) => {
    if (str || str === "") {
        str = `${str}`.trim();
        
        if (str == "") {
            return true;
        }
        if (str === "-") {
            return true;
        }
        if (str.toLowerCase() === "done") {
            return true;
        }
        if (isInteger(str)) {
            return true;
        }

        console.log("str: " + str)
        return false;
    } else {
        console.log("str: " + str)
        return false;
    }
};

export const get_job = (key) => {
    var job_arr = key.split("_");
    if (job_arr.length === 2) {
        return job_arr[1];
    }
    return undefined;
}

export const get_opt = (activity, value) => {
    if (activity === "exercise" && isInteger(value)) {
        return "update";
    } else if (activity === "exercise" && !isInteger(value)) {
        return "delete";
    } else if (activity === "custom" && isInteger(value)) {
        return "update";
    } else if (activity === "custom" && !isInteger(value)) {
        return "delete";
    } else if ((activity === "media" && value === "-") || (activity === "media" && isInteger(value))) {
        return "update";
    } else {
        return "delete";
    }
}

export const isInteger = (str) => {
    var val = `${str}`;
    const isnum = !isNaN(val) && val.trim() !== '';
    return isnum;
}

export const isNumeric = (n) => {
    var val = `${n}`;
    return !isNaN(parseFloat(val)) && isFinite(val);
}

export const isExerciseScore = (str) => {
    var original_str = str;
    str = `${str}`.trim();
    if (!str) {
        return false;
    }
    if (str === "-") {
        return true;
    }

    if (isNumeric(original_str)) {
        return true;
    } else {
        return false;
    }
}