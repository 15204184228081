import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    Select,
    Alert,
    AlertIcon,
    Flex,
    FormErrorMessage,
    useToast,
    Checkbox,
    CheckboxGroup,
    Stack,
    Text,
} from '@chakra-ui/react';
import axios from 'axios';
import { AddIcon, DeleteIcon, DownloadIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { showSuccessToast, showWarningToast, showErrorToast } from 'util/alertUtil';
import Swal from 'sweetalert2';
import { FaFileImport, FaWeight } from 'react-icons/fa';
import { isExerciseScore, get_job, get_opt, isMediaValid, isInteger } from 'util/common';
import { useAuth } from 'auth/AuthProvider';
import { BsEyeFill } from 'react-icons/bs';
import Breadcrumbs from './widgets/Breadcrumbs';
import { BsFillEyeSlashFill } from 'react-icons/bs'; 

function ActivityScore() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
    const { isOpen: isUploadOpen, onOpen: onUploadOpen, onClose: onUploadClose } = useDisclosure();
    const { isOpen: isWeightedOpen, onOpen: onWeightedOpen, onClose: onWeightedClose } = useDisclosure();
    const { isOpen: isPreviewOpen, onOpen: onPreviewOpen, onClose: onPreviewClose } = useDisclosure();
    const { isOpen: isHideOpen, onOpen: onHideOpen, onClose: onHideClose } = useDisclosure();

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [columnTitle, setColumnTitle] = useState('');
    const [selectedColumn, setSelectedColumn] = useState('');
    const [file, setFile] = useState(null);
    const [alert, setAlert] = useState('');
    const [uploadedData, setUploadedData] = useState([]);
    const [fetchedColumns, setFetchedColumns] = useState([]); // New state variable
    const [originalColumn, setOriginalColumn] = useState(''); // For weighted column
    const [maxOldScore, setMaxOldScore] = useState(''); // For weighted column
    const [maxNewScore, setMaxNewScore] = useState(''); // For weighted column
    const [selectedColumnsToHide, setSelectedColumnsToHide] = useState([]);
    const [errors, setErrors] = useState({});
    const toast = useToast();
    const [metadataHeader, setMetadataHeader] = useState([]);

    const [classroomID, setClassroomID] = useState(searchParams.get('classroom_id') ?? "")
    const [activityID, setActivityID] = useState(searchParams.get('activity_id') ?? "")
    const [activityName, setActivityName] = useState(searchParams.get('activity') ?? "")
    const { role } = useAuth();

    const breadcumLinks = [
        { url: '', text: searchParams.get('activity') ?? "" },
        { url: undefined, text: 'คะแนนการทำกิจกรรม' }
    ];

    useEffect(()=>{
        setClassroomID(searchParams.get('classroom_id'))
        setActivityID(searchParams.get('activity_id'))
    },[])

    useEffect(() => {
        if(classroomID && activityID){
            fetchData();
            fetchColumn(); // Fetch columns on component mount
        }
    }, [classroomID, activityID]);

    useEffect(() => {
        console.log(`selectedColumnsToHide: ${selectedColumnsToHide}`)
    }, [selectedColumnsToHide])

    const processRows = (columns, rows) => {
        return rows.map(row => {
            return row.map((cell, index) => {
                // Check if the column header contains "me" and the cell is an integer
                if (columns[index].includes("me") && Number.isInteger(cell)) {
                    return "done";
                }
                return cell;
            });
        });
    };
    
    const fetchData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/activity_score/list_activity_result`, {
                classroom_id: classroomID,
                ipst_activity_id: activityID,
            }, { withCredentials: true });
            const result = response.data.result;
            if (result && result.length > 2) {
                console.log(`result:: ${JSON.stringify(result)}`)
                setColumns(result[0]);
                setMetadataHeader(result[1]);
                //setRows(result.slice(2));

                const processedRows = processRows(result[0], result.slice(2));
                setRows(processedRows);
            } else {
                setAlert('No data available');
            }
        } catch (error) {
            console.log(error)
            setAlert('Error fetching data!');
        }
    };

    const fetchColumn = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/jobs/list_all_columns`, {
                ipst_activity_id: activityID,
                job_type: "all"
            }, { withCredentials: true });
            const result = response.data;
            if (result && result.status === "success") {
                console.log(`result.columns: ${JSON.stringify(result.columns)}`)
                setFetchedColumns(result.columns);

                const columnsWithStatus = result.columns.map((col) => ({
                    job_id: col.id,
                    visible: col.is_student_visible === 1,
                }));
                setSelectedColumnsToHide(columnsWithStatus);

            } else {
                setAlert('Error fetching columns');
            }
        } catch (error) {
            setAlert('Error fetching columns!');
        }
    };

    const checkValid = (json_objs, hd_dictionary) => {
        var is_row_valid = true;
        var in_valid_sym = "";
        var job_arr = [];

        // Filter out records that have no "id" and no "name"
        json_objs = json_objs.filter(obj => obj.id !== undefined && obj.name !== undefined && obj.id !== "" && obj.name !== "");

        console.log(JSON.stringify(json_objs))
        json_objs.forEach((obj) => {
            var student_id = undefined;
            //console.log(JSON.stringify(obj))

            let keys = Object.keys(obj);
            if (keys.length > 0) {
                keys.forEach((key) => {
                    if (hd_dictionary[key] === undefined) {
                        is_row_valid = false;
                        console.log('a1')
                        in_valid_sym = "column " + key + " not found";
                        console.log("incorrect: 1")
                    }

                    if (key.indexOf("ex") >= 0) {
                        if (!isExerciseScore(obj[key])) {
                            in_valid_sym = `${obj[key]} (กรณีของแบบฝึกหัดค่าต้องเป็นตัวเลข หรือเครื่องหมาย "-" เท่านั้น)`;
                            is_row_valid = false;
                            console.log('a2')
                            console.log(`incorrect: 2: ${obj[key]}`)
                        } else {
                            var activity = "exercise";
                            var job_id = get_job(key);
                            var value = obj[key] && `${obj[key]}`.toLowerCase().trim() == "-"? "" : obj[key];

                            var opt = get_opt(activity, obj[key]);
                            var job = { job_id, opt, activity, student_id, value };
                            if (opt === "update") {
                                job_arr.push(job);
                            }
                        }
                    } else if (key.indexOf("cu") >= 0) {
                        if (!isExerciseScore(obj[key])) {
                            in_valid_sym = `${obj[key]} (กรณีของข้อมูลแบบ custom ค่าต้องเป็นตัวเลข หรือเครื่องหมาย "-" เท่านั้น)`;
                            is_row_valid = false;
                            console.log('a3')
                            console.log("incorrect: 3")
                            Swal.fire("Error!", "ข้อมูลไม่ถูกต้อง.", "warning");
                        } else {
                            var activity = "custom";
                            var job_id = get_job(key);
                            var value = obj[key] && `${obj[key]}`.toLowerCase().trim() == "-"? "" : obj[key];

                            var opt = get_opt(activity, obj[key]);
                            var job = { job_id, opt, activity, student_id, value };
                            if (opt === "update") {
                                job_arr.push(job);
                            }
                        }
                    } else if (key.indexOf("me") >= 0 && key !== "name") {
                        
                        //console.log(`obj[key]: ${obj[key]}`)
                        if (obj[key] !== undefined && (!isMediaValid(obj[key]) || isInteger(obj[key]))) {
                            //console.log(`do2 ----> ${opt}`)
                            in_valid_sym = `${obj[key]} (กรณีของข้อมูลแบบ media ค่าต้องเป็น "done" หรือเครื่องหมาย "-" เท่านั้น)`;
                            is_row_valid = false;
                            //console.log('a4')
                            console.log("incorrect: 4")
                        } else {
                            console.log(`do1 ----> ${obj[key]}`)
                            var activity = "media";
                            var job_id = get_job(key);
                            var value = obj[key] && `${obj[key]}`.toLowerCase().trim() == "done"? "1" : obj[key];
                            value = obj[key] && `${obj[key]}`.toLowerCase().trim() == "-"? "" : value;

                            var opt = get_opt(activity, value);
                            var job = { job_id, opt, activity, student_id, value };
                            if (opt === "update") {
                                job_arr.push(job);
                                console.log("job1: " + JSON.stringify(job))
                            }

                            //console.log("job2: " + JSON.stringify(job))
                        }
                    } else if (key === "id") {
                        student_id = obj[key];
                    } else if (key !== "name" && key !== "id") {
                        in_valid_sym = `ไม่พบหัวข้อ '${key}' ในระบบ`;
                        is_row_valid = false;
                        console.log("incorrect: 5")
                    }
                });
            }
        });

        if (is_row_valid) {
            console.log(JSON.stringify(job_arr))
            if (job_arr.length > 0) {
                importData(job_arr);
            } else {
                Swal.fire("Warning", "ข้อมูลไม่ถูกต้อง..", "warning");
            }
        } else {
            Swal.fire("Warning", "ข้อมูลไม่ถูกต้อง: " + in_valid_sym, "warning");
        }
    };

    const importData = (jobArr) => {
        const jobs = JSON.stringify(jobArr);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/activity_score/import_job_status`,
                {
                    classroom_id: classroomID,
                    activity_id: activityID,
                    job_arr: jobs,
                },
                { withCredentials: true }
            )
            .then((res) => {
                if (res.data && res.data.status === "success") {
                    onUploadClose();
                    onPreviewClose();
                    fetchData();
                    Swal.fire("Success!", "นำเข้าข้อมูลเรียบร้อย", "success");
                    fetchData();
                } else {
                    Swal.fire("Warning!", "เกิดข้อผิดพลาดระหว่างนำเข้าข้อมูล กรุณาลองอีกครั้ง", "warning");
                }
            })
            .catch((error) => {
                console.error("Error importing data", error);
                Swal.fire("Error!", "ข้อมูลไม่ถูกต้อง", "warning");
            });
    };

    /*const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            setUploadedData(jsonData);

            console.log(jsonData)
            onPreviewOpen();
        };
        reader.readAsArrayBuffer(selectedFile);
    };*/

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
    
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
    
            // Preserve empty cells and prevent column shifting
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "" });
    
            if (jsonData.length < 2) {
                showWarningToast(toast, "แจ้งเตือน", "ไฟล์ไม่มีข้อมูล");
                return;
            }
    
            // Set the first row as headers (column names)
            const headers = jsonData[0].map(header => header?.toString().trim());
            setMetadataHeader(headers);
    
            // Ensure data rows have the same number of columns as the headers
            const dataRows = jsonData.slice(1).map(row => {
                while (row.length < headers.length) {
                    row.push(""); // Fill missing columns with empty strings
                }
                return row;
            });
    
            setUploadedData([headers, ...dataRows]); // Keep headers in uploadedData
    
            console.log("Extracted Headers:", headers);
            console.log("Final Processed Data:", dataRows);
    
            onPreviewOpen();
        };
        reader.readAsArrayBuffer(selectedFile);
    };
    
    
    
    
    

    const handleFileUpload = async () => {
        if (uploadedData.length < 2) {
            showWarningToast(toast, "แจ้งเตือน", "No data to upload");
            return;
        }

        const dataToUpload = uploadedData.slice(1).map((row) => {
            const obj = {};
            metadataHeader.forEach((key, index) => {
                obj[key] = row[index];
            });
            return obj;
        });

        const hdDictionaryTemp = metadataHeader.reduce((acc, h) => ({ ...acc, [h]: h }), {});
        checkValid(dataToUpload, hdDictionaryTemp);
    };

    const handleAddColumn = async () => {
        if (!columnTitle) {
            setErrors({ columnTitle: "Column title is required" });
            return;
        }

        const data = {
            operation: "insert",
            column_insert_type: "new_column",
            classroom_id: classroomID,
            job_id: "",
            job_type: "custom",
            ipst_activity_id: activityID,
            media_title: columnTitle,
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/jobs/job_save`, data, { withCredentials: true });
            if (response.data.status === "success") {
                fetchData();
                fetchColumn();
                onClose();
                setColumnTitle("");
                showSuccessToast(toast, "สำเร็จ", "เพิ่ม column ใหม่เรียบร้อย");
            } else {
                showWarningToast(toast, "แจ้งเตือน", "ไม่สามารถเพิ่ม column ได้");
            }
        } catch (error) {
            showErrorToast(toast, "เกิดข้อผิดพลาด", "ไม่สามารถเพิ่ม column ได้");
        }
    };

    const handleAddWeightedColumn = async () => {
        let validationErrors = {};
        if (!originalColumn) validationErrors.originalColumn = "กรุณาเลือก column";
        if (!maxOldScore) validationErrors.maxOldScore = "กรุณากรอกคะแนน (ตัวเลข)";
        if (!maxNewScore) validationErrors.maxNewScore = "กรุณากรอกคะแนน (ตัวเลข)";
        if (!columnTitle) validationErrors.columnTitle = "กรุณากรอกชื่อ column";

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const data = {
            operation: "insert",
            column_insert_type: "new_weighted_column",
            classroom_id: classroomID,
            job_id: "",
            job_type: "custom",
            ipst_activity_id: activityID,
            media_title: columnTitle,
            original_job_id: originalColumn,
            max_score: maxNewScore,
            max_old_score: maxOldScore,
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/jobs/job_save`, data, { withCredentials: true });
            if (response.data.status === "success") {
                fetchData();
                fetchColumn();
                onWeightedClose();
                setColumnTitle("");
                setOriginalColumn("");
                setMaxOldScore("");
                setMaxNewScore("");
                showSuccessToast(toast, "สำเร็จ", "เพิ่ม column ถ่วงน้ำหนักเรียบร้อย");
            } else {
                showWarningToast(toast, "แจ้งเตือน", "ไม่สามารถเพิ่ม column ถ่วงน้ำหนักได้");
            }
        } catch (error) {
            showErrorToast(toast, "เกิดข้อผิดพลาด", "ไม่สามารถเพิ่ม column ถ่วงน้ำหนักได้");
        }
    };

    const handleHideColumn = async () => {
        const result = await Swal.fire({
            title: 'ยืนยันการซ่อน?',
            text: `คุณต้องการซ่อนคอลัมน์ที่เลือกใช่หรือไม่ !`,
            icon: 'warning',
            denyButtonColor: "#6CB4EE",
            confirmButtonColor: "#ED0A3F",
            showCancelButton: true,
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ยกเลิก!',
        });

        if (result.isConfirmed) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/activity_score/save_column_visibility`, {
                    job_visibilities: JSON.stringify(selectedColumnsToHide),
                }, { withCredentials: true });
                if (response.data.status === "success") {
                    fetchData();
                    onHideClose();
                    showSuccessToast(toast, "สำเร็จ", "ซ่อน column เรียบร้อย");
                } else {
                    showWarningToast(toast, "แจ้งเตือน", "ไม่สามารถซ่อน column ได้");
                }
            } catch (error) {
                showErrorToast(toast, "เกิดข้อผิดพลาด", "ไม่สามารถซ่อน column ได้");
            }
        }
    };

    const handleDeleteColumn = async () => {
        if (!selectedColumn) {
            showWarningToast(toast, "แจ้งเตือน", "No column selected for deletion");
            return;
        }

        const result = await Swal.fire({
            title: 'ยืนยันการลบ?',
            text: `คุณต้องการลบคอลัมน์ใช่หรือไม่ !`,
            icon: 'warning',
            denyButtonColor: "#6CB4EE",
            confirmButtonColor: "#ED0A3F",
            showCancelButton: true,
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ยกเลิก!',
        });

        if (result.isConfirmed) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/jobs/delete_job`, { job_id: selectedColumn }, { withCredentials: true });
                if (response.data.status === "success") {
                    fetchData();
                    fetchColumn();
                    onDeleteClose();
                    setSelectedColumn("");
                    showSuccessToast(toast, "สำเร็จ", "ลบ column เรียบร้อย");
                } else {
                    showWarningToast(toast, "แจ้งเตือน", "ไม่สามารถลบ column ได้");
                }
            } catch (error) {
                showErrorToast(toast, "เกิดข้อผิดพลาด", "ไม่สามารถลบ column ได้");
            }
        }
    };

    const exportToExcel = () => {
        const ws_data = [rows.length > 0 ? metadataHeader : [], ...rows];
        const worksheet = XLSX.utils.aoa_to_sheet(ws_data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Activity Scores");
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(data, "activity_scores.xlsx");
    };

    /*const handleColumnsToHide = (val) => {
        var uniqueValues = []
        if(typeof val == "string"){
            uniqueValues = [...new Set(val.split(',').map(value => value.trim()).filter(value => value !== ''))];
        }
        else{
            uniqueValues = [...new Set(val)];
        }
        console.log(`${typeof val}`)
        console.log(`${typeof uniqueValues}`)
        setSelectedColumnsToHide(uniqueValues)
    }*/

    const handleColumnsToHide = (val) => {
        let columnsWithStatus = fetchedColumns.map((col) => {
            return {
                job_id: col.id,
                visible: !val.includes(col.id.toString())
            };
        });
    
        console.log(columnsWithStatus);
        setSelectedColumnsToHide(columnsWithStatus);
        console.log(`columnsWithStatus: ${JSON.stringify(columnsWithStatus)}`)
    };
        

    return (
        <Flex direction="column" pt={{ base: "65px", sm: "85px" }}>
            <Breadcrumbs links={breadcumLinks} />
            <Flex mt={3} w="100%">
                <Flex w="100%" direction="column" mr={5}>
                    {alert && (
                        <Alert status="error" mb={5}>
                            <AlertIcon />
                            {alert}
                        </Alert>
                    )}
                    {role != "STUDENT" && <Box mb={5}>
                        <Button size={"sm"} variant='outline' leftIcon={<AddIcon />} colorScheme="blue" onClick={onOpen} mr={3}>
                            เพิ่ม column
                        </Button>
                        <Button size={"sm"} variant='outline' leftIcon={<FaWeight />} colorScheme="blue" onClick={onWeightedOpen} mr={3}>
                            ปรับถ่วงน้ำหนักคะแนน
                        </Button>
                        <Button size={"sm"} variant='outline' leftIcon={<DeleteIcon />} colorScheme="red" onClick={onDeleteOpen} mr={3}>
                            ลบ column
                        </Button>
                        <Button size={"sm"} variant='outline' leftIcon={<FaFileImport />} colorScheme="blue" onClick={onUploadOpen} mr={3}>
                            นำเข้าคะแนน
                        </Button>
                        <Button size={"sm"} variant='outline' leftIcon={<DownloadIcon />} colorScheme="blue" onClick={exportToExcel} mr={3}>
                            Export Excel
                        </Button>
                        <Button size={"sm"} variant='outline' leftIcon={<BsFillEyeSlashFill />} colorScheme="blue" onClick={onHideOpen}>
                            ซ่อน column จากนักเรียน
                        </Button>
                    </Box>}

                    <Table variant="striped">
                        <Thead>
                            <Tr>
                                {columns.map((col, index) => {
                                    // Find the column visibility status from `selectedColumnsToHide`
                                    const columnStatus = selectedColumnsToHide.find(
                                        (item) => col.includes(item.job_id)
                                    );

                                    return (
                                        <Th key={index}>
                                            <Flex dir={"row"}>
                                                {role !== 'STUDENT' && columnStatus && !columnStatus.visible && (
                                                    <Box as={BsFillEyeSlashFill} mr={1} mt={"2px"} color="orange.500" title="ซ่อนจากนักเรียน" />
                                                )}
                                                <Text>{col}</Text>
                                            </Flex>
                                        </Th>
                                    );
                                })}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {rows.map((row, rowIndex) => (
                                <Tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <Td key={cellIndex}>{cell}</Td>
                                    ))}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>

                    {/* Add Column Modal */}
                    <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>เพิ่ม column ใหม่</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <FormControl id="columnTitle" isInvalid={errors.columnTitle}>
                                    <FormLabel>ชื่อ column</FormLabel>
                                    <Input value={columnTitle} onChange={(e) => {
                                        setColumnTitle(e.target.value);
                                        setErrors((prevErrors) => ({ ...prevErrors, columnTitle: "" }));
                                    }} />
                                    <FormErrorMessage>{errors.columnTitle}</FormErrorMessage>
                                </FormControl>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={handleAddColumn}>
                                    บันทึก
                                </Button>
                                <Button variant="ghost" onClick={onClose}>
                                    ยกเลิก
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    {/* Add Weighted Column Modal */}
                    <Modal isOpen={isWeightedOpen} onClose={onWeightedClose} onShow={fetchColumn}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>เพิ่ม column ถ่วงน้ำหนัก</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <FormControl id="originalColumn" isInvalid={errors.originalColumn}>
                                    <FormLabel>เลือก column ต้นทาง</FormLabel>
                                    <Select placeholder="Select column" onChange={(e) => {
                                        setOriginalColumn(e.target.value);
                                        setErrors((prevErrors) => ({ ...prevErrors, originalColumn: "" }));
                                    }}>
                                        {fetchedColumns.map((col) => (
                                            <option key={col.id} value={col.id}>
                                                {col.media_title}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage>{errors.originalColumn}</FormErrorMessage>
                                </FormControl>
                                <FormControl id="maxOldScore" mt={4} isInvalid={errors.maxOldScore}>
                                    <FormLabel>คะแนนสูงสุดของ column ต้นทาง</FormLabel>
                                    <Input type="number" value={maxOldScore} onChange={(e) => {
                                        setMaxOldScore(e.target.value);
                                        setErrors((prevErrors) => ({ ...prevErrors, maxOldScore: "" }));
                                    }} />
                                    <FormErrorMessage>{errors.maxOldScore}</FormErrorMessage>
                                </FormControl>
                                <FormControl id="maxNewScore" mt={4} isInvalid={errors.maxNewScore}>
                                    <FormLabel>คะแนนสูงสุดของ column ใหม่</FormLabel>
                                    <Input type="number" value={maxNewScore} onChange={(e) => {
                                        setMaxNewScore(e.target.value);
                                        setErrors((prevErrors) => ({ ...prevErrors, maxNewScore: "" }));
                                    }} />
                                    <FormErrorMessage>{errors.maxNewScore}</FormErrorMessage>
                                </FormControl>
                                <FormControl id="weightedColumnTitle" mt={4} isInvalid={errors.columnTitle}>
                                    <FormLabel>ชื่อ column ใหม่</FormLabel>
                                    <Input value={columnTitle} onChange={(e) => {
                                        setColumnTitle(e.target.value);
                                        setErrors((prevErrors) => ({ ...prevErrors, columnTitle: "" }));
                                    }} />
                                    <FormErrorMessage>{errors.columnTitle}</FormErrorMessage>
                                </FormControl>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={handleAddWeightedColumn}>
                                    บันทึก
                                </Button>
                                <Button variant="ghost" onClick={onWeightedClose}>
                                    ยกเลิก
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    {/* Delete Column Modal */}
                    <Modal isOpen={isDeleteOpen} onClose={onDeleteClose} onShow={fetchColumn}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>ลบ Column</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <FormControl id="deleteColumn" isInvalid={errors.selectedColumn}>
                                    <FormLabel>เลือก column ที่ต้องการลบ</FormLabel>
                                    <Select placeholder="-- เลือก --" onChange={(e) => {
                                        setSelectedColumn(e.target.value);
                                        setErrors((prevErrors) => ({ ...prevErrors, selectedColumn: "" }));
                                    }}>
                                        {fetchedColumns.map((col) => (
                                            col.job_type === "custom" && (
                                                <option key={col.id} value={col.id}>
                                                    {col.media_title}
                                                </option>
                                            )
                                        ))}
                                    </Select>
                                    <FormErrorMessage>{errors.selectedColumn}</FormErrorMessage>
                                </FormControl>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="red" mr={3} onClick={handleDeleteColumn}>
                                    ลบ
                                </Button>
                                <Button variant="ghost" onClick={onDeleteClose}>
                                    ยกเลิก
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    {/* Hide Column Modal */}
                    <Modal isOpen={isHideOpen} onClose={onHideClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>ซ่อน Column จากนักเรียน</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <FormControl id="hideColumns">
                                    <FormLabel>เลือก column ที่ต้องการซ่อน</FormLabel>
                                    {/*<CheckboxGroup colorScheme='red' value={[...selectedColumnsToHide]} onChange={handleColumnsToHide}>
                                        <Stack spacing={3}>
                                            {fetchedColumns.map((col) => (
                                                <Checkbox key={`box_${col.id}`} value={`${col.id}`}>
                                                    {col.media_title}
                                                </Checkbox>
                                            ))}
                                        </Stack>
                                    </CheckboxGroup>*/}
                                    <CheckboxGroup
                                        colorScheme="red"
                                        value={selectedColumnsToHide
                                            .filter((col) => !col.visible) // Include only selected (hidden) columns
                                            .map((col) => col.job_id.toString())} // Map to the array of job_id strings
                                        onChange={(selectedValues) => {
                                            // Pass the selected job_id values (as strings) to the handler
                                            handleColumnsToHide(selectedValues);
                                        }}
                                    >
                                        <Stack spacing={3}>
                                            {fetchedColumns.map((col) => (
                                                <Checkbox key={`box_${col.id}`} value={col.id.toString()}>
                                                    {col.media_title}
                                                </Checkbox>
                                            ))}
                                        </Stack>
                                    </CheckboxGroup>

                                </FormControl>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={handleHideColumn}>
                                    บันทึก
                                </Button>
                                <Button variant="ghost" onClick={onHideClose}>
                                    ยกเลิก
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    {/* File Upload Modal */}
                    <Modal isOpen={isUploadOpen} onClose={onUploadClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>อัพโหลดคะแนน</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <FormControl id="fileUpload" isInvalid={errors.file}>
                                    <FormLabel>เลือกไฟล์</FormLabel>
                                    <Input type="file" onChange={handleFileChange} />
                                    <FormErrorMessage>{errors.file}</FormErrorMessage>
                                </FormControl>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={handleFileUpload}>
                                    อัพโหลด
                                </Button>
                                <Button variant="ghost" onClick={onUploadClose}>
                                    ยกเลิก
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    {/* Preview Data Modal */}
                    <Modal isOpen={isPreviewOpen} onClose={onPreviewClose} size="6xl">
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>ตัวอย่างข้อมูลที่จะอัพโหลด</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            {uploadedData[0] && uploadedData[0].map((col, index) => (
                                                <Th key={index}>{col}</Th>
                                            ))}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {uploadedData.slice(1).map((row, rowIndex) => (
                                            <Tr key={rowIndex}>
                                                {row.map((cell, cellIndex) => (
                                                    <Td key={cellIndex}>{cell}</Td>
                                                ))}
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={handleFileUpload}>
                                    ยืนยันการอัพโหลด
                                </Button>
                                <Button variant="ghost" onClick={onPreviewClose}>
                                    ยกเลิก
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default ActivityScore;
