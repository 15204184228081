import React, { useEffect, useState } from 'react';
import {
  Flex,
  Text,
  Button,
  Image,
  IconButton,
  Link,
  Spinner,
  Box,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { FaArrowDown, FaArrowUp, FaPencilAlt, FaPlusCircle, FaTrashAlt, FaYoutube } from "react-icons/fa";
import { getParam } from "util/common";
import QuestionYoutube from "./widgets/QuestionYoutube";
import Swal from 'sweetalert2';
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { HSeparator } from "components/Separator/Separator";
import SocialMedia from "assets/svg/social_media.svg";
import ManageJobModal from './modals/ManageJobModal';
import ExamPreview from './widgets/ExamPreview';
import { useAuth } from 'auth/AuthProvider';
import { showErrorToast } from 'util/alertUtil';
import { showWarningToast } from 'util/alertUtil';
import { showSuccessToast } from 'util/alertUtil';
import { showInfoToast } from 'util/alertUtil';
import Breadcrumbs from './widgets/Breadcrumbs';
import LoadingModal from './modals/LoadingModal';
import { showWarningDialog } from 'util/alertUtil';

const MediaList = () => {
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [currentJob, setCurrentJob] = useState();
  const [currentJobIndex, setCurrentJobIndex] = useState();
  const [editJob, setEditJob] = useState();
  const [activityName, setActivityName] = useState("");
  const [activityID, setActivityID] = useState();
  const [showYoutube, setShowYoutube] = useState(false);
  const [viewType, setViewType] = useState("admin");
  const [operation, setOperation] = useState('insert');
  const searchParams = new URLSearchParams(location.search);

  const [subjectID, setSubjectID] = useState();
  const [lessonID, setLessonID] = useState();
  const [lessonName, setLessonName] = useState("");
  const [initAnswer, setInitAnswer] = useState();
  const [countAllAnswers, setCountAllAnswers] = useState(0)
  const [allowAnswerEdit, setAllowAnswerEdit] = useState(false)
  const [studentScores, setStudentScores] = useState()
  const [youtubeSubmitted, setYoutubeSubmitted] = useState(false)
  const [youtubeScore, setYoutubeScore] = useState()

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, role, getUser } = useAuth();
  const toast = useToast()

  const { isOpen: isOpenLoadingModal, onOpen: onOpenLoadingModal, onClose: onCloseLoadingModal } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const breadcumLinks = [
    { url: `/#/user/activity-list?subject_id=${getParam('subject_id')}&lesson_id=${getParam('lesson_id')}&lesson=${getParam('lesson')}&classroom_id=${getParam('classroom_id')}&cname=${getParam('cname')}`, text: `${getParam('lesson')}` },
    { url: undefined, text: `${getParam('activity')}` }
];

  useEffect(() => {
    const acc = getParam("activity");
    const accid = getParam("activity_id");
    const lessonTitle = getParam('lesson');
    const view = getParam('view');
    const subjectid = getParam('subject_id');
    const lessonid = getParam('lesson_id');

    setActivityID(accid);
    setActivityName(acc);
    setLessonName(lessonTitle);
    setSubjectID(subjectid);
    setLessonID(lessonid);

    if (view) {
      setViewType(view);
    }
    loadAllJobs(accid);
  }, []);

  useEffect(() => {
    if(isLoading){
      onOpenLoadingModal()
    }
    else{
      onCloseLoadingModal()
    }
  },[isLoading])

  useEffect(() => {
    if (currentJob) {
      //setStudentMaterialJobStatus(currentJob.job_type)
      setStudentMaterialJobStatus()
      loadJobStatus()
    }

  }, [currentJob])

  const loadAllJobs = (ipst_activity_id) => {
    setLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/jobs/list_all_jobs`, { ipst_activity_id })
      .then(response => {
        const res = response.data;
        if (res !== undefined && res.status === "success") {
          setJobs(res.jobs);
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        Swal.fire('Error!7', "Cannot connect to the server!", 'warning');
      });
  }

  const openJob = (job, index) => {
    console.log(`job id: ${job.id}`)
    setCurrentJob(job);
    setCurrentJobIndex(index)
  }

  async function countStudentAnswers(student_id, classroom_id, activity_id, job_id) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/exams/count_student_all_answers`, {
        student_id: student_id,
        classroom_id: classroom_id,
        activity_id: activity_id,
        job_id: job_id
      });

      const res = response.data;
      if (res && res.status === "success") {
        const count_student_all_answers = res.answer_num;
        //alert(`count_student_all_answers: ${count_student_all_answers}`)
        setCountAllAnswers(count_student_all_answers)
      } else {
        showWarningToast(toast, 'warning', 'Failed to retrieve the correct answer count.');
      }
    } catch (error) {
      showWarningToast(toast, "Error!", `${error}`);
    }
  }

  async function countStudentCorrectAnswers(student_id, classroom_id, activity_id, job_id) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/exams/count_student_correct_answers`, {
        student_id: student_id,
        classroom_id: classroom_id,
        activity_id: activity_id,
        job_id: job_id
      });

      const res = response.data;
      if (res && res.status === "success") {
        const cur_correct_answered_count = res.correct_num;
        return cur_correct_answered_count;
      } else {
        throw new Error('Failed to retrieve the correct answer count.');
      }
    } catch (error) {
      showWarningToast("Error!9", 'Cannot fetch data from the server!');
      throw error;
    }
  }

  const loadJobStatus = async () => {
    const u = getUser()
    const classroom_id = getParam("classroom_id");

    if (currentJob && currentJob.id && role == "STUDENT" && u && u.id && classroom_id) {
        const student_id = u.id;
        const job_id = currentJob.id;
        const activity_id = activityID;

        try {
            setIsLoading(true)
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/exams/get_job_status`, {
                student_id: student_id,
                classroom_id: classroom_id,
                activity_id: activity_id,
                job_id: job_id
            }, { withCredentials: true });

            const res = response.data;
            
            if (res && res.status === "success") {
                if (res.result) {
                    const job_status = res.result;
                    const is_exam_submmited = job_status.is_submitted;
                    const is_allow_submit_once = job_status.is_submit_once;
                    const job_score = job_status.job_score;

                    if(currentJob.job_type == "exercise"){
                      if (is_exam_submmited === 0) {
                        setAllowAnswerEdit(true);
                      } else {
                        setAllowAnswerEdit(!is_allow_submit_once);
                      }

                      const studentScore = await countStudentCorrectAnswers(u.id, classroom_id, activityID, currentJob.id)
                      if(studentScore){
                        console.log(`studentScore: ${studentScore}`)
                        setStudentScores(studentScore)
                      }
                    }
                    else if(currentJob.job_type == "youtube_exercise"){
                      setYoutubeSubmitted(is_exam_submmited)
                      setYoutubeScore(job_score)
                    }
                } else {
                  if(currentJob.job_type == "exercise"){
                    setAllowAnswerEdit(true)
                  }
                  else if(currentJob.job_type == "youtube_exercise"){
                    setYoutubeSubmitted(false)
                    setYoutubeScore()
                  }
                }
                setIsLoading(false)
            }
        } catch (error) {
            showWarningToast(toast, "Error!10", `${error}`);
            setIsLoading(false)
        }
    }
}

  async function onQuestionChange(question) {
    //console.log(`---------> ${JSON.stringify(user)}`)
    const u = getUser()
    const classroom_id = getParam("classroom_id");

    if (currentJob && currentJob.id && role == "STUDENT" && u && u.id && classroom_id) {
      try {
        countStudentAnswers(u.id, classroom_id, activityID, currentJob.id);

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/exams/get_current_student_answer`, {
          student_id: u.id,
          classroom_id,
          activity_id: activityID,
          job_id: currentJob.id,
          question_id: question.id
        }, { withCredentials: true });

        const res = response.data;

        if (res && res.status === "success") {
          const answer_id = res.answer_id;
          const is_correct = res.is_correct;
          const answer_text = res.answer_text;

          if (question.question_type === "choice" && res.answer_id) {
            setInitAnswer({
              answered_id: answer_id,
              is_correct
            })
          }
          else if (question.question_type === "fill") {
            setInitAnswer({
              answer_text,
              is_correct
            })
          }
          else if (question.question_type === "matching") {
            if (answer_text) {
              if (typeof (answer_text) == "string") {
                setInitAnswer(JSON.parse(answer_text))
              }
              else if (typeof (answer_text) == "object") {
                setInitAnswer(answer_text)
              }
            }
          }
        }
      } catch (error) {
        showWarningToast(toast, "Error!!", `${error}`);
      }
    }
  }

  const submitYoutube = async (student_score, full_scores) => {
    const u = getUser()
    const classroom_id = getParam("classroom_id");

    if (currentJob && currentJob.id && role == "STUDENT" && u && u.id && classroom_id) {
      const is_finished = 1;
      const is_submitted = 1;
      setIsLoading(true)

      if(!u.id){
        showWarningDialog("แจ้งเตือน", `ไม่สามารถอ่านข้อมุลนักเรียนได้ (student id: ${u.id}) `)
        return;
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/exams/set_student_job_status`, {
            student_id: u.id,
            classroom_id: classroom_id,
            activity_id: activityID,
            job_id: currentJob.id,
            is_finished: is_finished,
            is_submitted: is_submitted,
            job_total_score: full_scores,
            job_score: student_score,
            job_type: "youtube_exercise"
        }, {
            withCredentials: true
        });

        const res = response.data;
        if (res && res.status === "success") {
            //showSuccessToast(toast, "สำเร็จ!", "บันทึกเรียบร้อย");
            setIsLoading(false)
            loadJobStatus()
        }
      } catch (error) {
          showErrorToast(toast, "Error!2", 'Cannot connect to the server!', 'warning');
          setIsLoading(false)
      }
    }
  }

  const submitExercise = async (full_scores) => {
    const u = getUser()
    const classroom_id = getParam("classroom_id");

    if (currentJob && currentJob.id && role == "STUDENT" && u && u.id && classroom_id) {
      const is_finished = 1;
      const is_submitted = 1;

      if(!u.id){
        showWarningDialog("แจ้งเตือน", `ไม่สามารถอ่านข้อมุลนักเรียนได้ (student id: ${u.id}) `)
        return;
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/exams/set_student_job_status`, {
            student_id: u.id,
            classroom_id: classroom_id,
            activity_id: activityID,
            job_id: currentJob.id,
            is_finished: is_finished,
            is_submitted: is_submitted,
            job_total_score: full_scores,
            job_type: "exercise"
        }, {
            withCredentials: true
        });

        const res = response.data;
        if (res && res.status === "success") {
            //showSuccessToast(toast, "สำเร็จ!", "บันทึกเรียบร้อย");
            loadJobStatus()
        }
      } catch (error) {
          showErrorToast(toast, "Error!3", 'Cannot connect to the server!', 'warning');
      }
    }
  }

  const updateStudentAnswer = async (testSetID, questionID, answerID, questionAnswer, isAnsweredCorrect, durationInSeconds) => {
    const u = getUser()
    const classroom_id = getParam("classroom_id");

    if (currentJob && currentJob.id && role == "STUDENT" && u && u.id && classroom_id) {
      const data = {
        test_set_id: testSetID,
        student_id: u.id,
        classroom_id,
        activity_id: activityID,
        job_id: currentJob.id,
        question_id: questionID ?? "",
        answer_id: answerID,
        answer_text: questionAnswer,
        is_correct_answer: isAnsweredCorrect,
        time_taken: durationInSeconds
      }
      //console.log(`is_correct_answer: ${isAnsweredCorrect}`)

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/exams/set_student_answer`, data, { withCredentials: true });
      countStudentAnswers(u.id, classroom_id, activityID, currentJob.id);

      const studentScore = await countStudentCorrectAnswers(u.id, classroom_id, activityID, currentJob.id)
      if(studentScore){
        setStudentScores(studentScore)
      }
      else{
        setStudentScores(0)
      }
    }
  }

  const setStudentMaterialJobStatus = () => {
    //console.log(JSON.parse(user).id)
    const u = getUser()
    const classroom_id = getParam("classroom_id");

    if (currentJob && currentJob.id && role == "STUDENT" && u && u.id && classroom_id) {
      const student_id = u.id;
      const job_id = currentJob.id;
      const activity_id = activityID;
      const is_finished = 1;
      const is_submitted = 1;

      if(!u.id){
        showWarningDialog("แจ้งเตือน", `ไม่สามารถอ่านข้อมุลนักเรียนได้ (student id: ${u.id}) `)
        return;
      }

      if (currentJob.job_type == "uploaded_material" || currentJob.job_type == "outside_material" || currentJob.job_type == "text") {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/exams/set_student_job_status`, {
          student_id,
          classroom_id,
          activity_id,
          job_id,
          is_finished,
          is_submitted,
        },{
          withCredentials: true
        })
        .then(response => {
          const res = response.data;
          if (res && res.status === "success") {
            // Handle success (e.g., show a notification, update UI, etc.)
            //alert('update status done')
          }
          setLoading(false);
        })
        .catch(error => {
          showErrorToast(toast, "Error!4", 'Cannot connect to the server!', 'warning');
          setLoading(false);
        });

        /*fetch(`${process.env.REACT_APP_API_URL}/exams/set_student_job_status`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            student_id,
            classroom_id,
            activity_id,
            job_id,
            is_finished,
            is_submitted,
          }),
        })
          .then(response => response.json())
          .then(res => {
            if (res && res.status === "success") {
              // Handle success (e.g., show a notification, update UI, etc.)
              //alert('update status done')
            }
            setLoading(false)
          })
          .catch(error => {
            showErrorToast(toast, "Error!4", 'Cannot connect to the server!', 'warning');
            setLoading(false)
          });*/
      }
    }
  }


  const handleAddJob = () => {
    setOperation('insert');
    setEditJob();
    onOpen();
  };

  const handleJobEdit = (job) => {
    console.log(JSON.stringify(job))
    setOperation('update');
    setEditJob(job);
    onOpen();
  }

  const onJobSaved = () => {
    loadAllJobs(activityID);
    onClose();
  };

  const deleteJobConfirm = (activity_title, job_id) => {
    Swal.fire({
      title: "ยืนยันการลบ",
      text: `คุณต้องการลบงาน ${activity_title} ใช่หรือไม่`,
      showCancelButton: true,
      denyButtonColor: "#6CB4EE",
      confirmButtonColor: "#ED0A3F",
      confirmButtonText: `ยืนยัน (ลบ)`,
      cancelButtonText: "ยกเลิก"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteJob(job_id);
      }
    })
  };

  function swapJobOrderByIDs(qid1, qid2, order1, order2) {
    console.log(`${qid1}, ${qid2}, ${order1}, ${order2}`)

    fetch(`${process.env.REACT_APP_API_URL}/jobs/swap_job_order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ qid1, qid2, order1, order2 })
    })
      .then(response => response.json())
      .then(res => {
        if (res.status === "success") {
          loadAllJobs(activityID);
          showInfoToast(toast, 'สำเร็จ!', 'สลับตำแหน่งเรียบร้อย!');
        } else {
          showInfoToast(toast, 'Error!', 'ไม่สามารถสลับตำแหน่งได้!');
        }
      })
      .catch(error => {
        showInfoToast(toast, 'Error!5', 'Cannot connect to the server!');
      });
  }


  const deleteJob = (job_id) => {
    axios.post(`${process.env.REACT_APP_API_URL}/jobs/delete_job`, { job_id })
      .then(response => {
        const res = response.data;
        if (res.status === "success") {
          loadAllJobs(activityID);
          Swal.fire('สำเร็จ!', 'ลบเรียบร้อย!', 'success');
        } else {
          Swal.fire('Error!', 'ไม่สามารถลบได้!', 'warning');
        }
      })
      .catch(error => {
        Swal.fire('Error!6', 'Cannot connect to the server!', 'warning');
      });
  };

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '100px' }}>
      <Breadcrumbs links={breadcumLinks} />
      <Flex w="100%">
        <Card>
          <CardHeader>
            <Flex justify={"space-between"}>
              <Flex direction={"column"}>
                <Text fontSize={20} fontWeight={500} mr={2}>{activityName}</Text>
                {currentJob && <Text fontSize={18}>{currentJob.media_title}</Text>}
              </Flex>
              {viewType == "admin" && <Button leftIcon={<FaPlusCircle />} variant="outline" colorScheme="blue" size="sm" onClick={handleAddJob}>เพิ่มงาน</Button>}
            </Flex>
          </CardHeader>
          <HSeparator my="10px" />
          <CardBody>
            {loading && <Flex w="100%" justify={"center"}>
              <Flex><Spinner mr={1} /><Text>Loading...</Text></Flex>
            </Flex>}
            {!loading && <Flex w="100%" direction={{ base: "column", md: "row" }} >
              {/*currentJob && currentJob.job_type == "youtube_exercise" && <QuestionYoutube yid={"NzoTF4lhVG0"} />*/}

              {currentJob && currentJob.job_type == "youtube_exercise" && <QuestionYoutube yid={currentJob.material_text} saveScore={submitYoutube} studentScores={youtubeScore} isSubmitted={youtubeSubmitted} />}

              {currentJob && currentJob.job_type == "uploaded_material" && (
                <Box w="100%" h="600px" border="1px solid #ccc">
                  <iframe
                    src={`${process.env.REACT_APP_API_URL}/files/${currentJob.material_pdf}`}
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                    title="PDF Preview"
                  />
                </Box>
              )}

              {currentJob && currentJob.job_type == "outside_material" && (
                <Flex borderRadius={10} borderWidth={"2px"} borderColor="blue.600" direction={"column"} w={{base: "100%", md: "70%"}} justify={"center"} align={"center"} p={2}>
                  <Link href={currentJob.material_url} isExternal>
                    คลิ๊กที่นี้เพื่อเข้าดูสื่อ
                  </Link>
                </Flex>
              )}

              {!currentJob && <Flex bgColor="blue.100" borderRadius={10} borderWidth={"2px"} borderColor="blue.600" direction={"column"} w={{base: "100%", md: "70%"}} justify={"center"} align={"center"} p={10}>
                <Image src={SocialMedia} maxW="250px" />
                <Text color="gray.400" size="sm">เลือกงานจากเมนูด้านขวา</Text>
              </Flex>}

              {currentJob && currentJob.job_type == "text" && <Flex borderRadius={10} borderWidth={{base: "0px", md: "2px"}} borderColor="blue.600" direction={"column"} w={{base: "100%", md: "70%"}} justify={"center"} align={"center"} p={2}>
                <Box dangerouslySetInnerHTML={{ __html: currentJob.material_text }} />
              </Flex>}

              {currentJob && currentJob.job_type == "exercise" && <Flex borderRadius={10} borderWidth={"2px"} borderColor="blue.600" direction={"column"}  w={{base: "100%", md: "70%"}} justify={"center"} align={"center"} p={2}>
                <ExamPreview
                  showAnswerByDefault={role=="TEACHER"?true:false}
                  studentScores={studentScores}
                  allowAnswerEdit={allowAnswerEdit}
                  studentAnswer={initAnswer}
                  exam_id={currentJob.ipst_exam_id}
                  submitExercise={submitExercise}
                  saveQuestionAnswer={updateStudentAnswer}
                  onQuestionChange={onQuestionChange}
                  isStudent={role=="STUDENT"?true:false}
                  countAllAnswers={countAllAnswers} />
              </Flex>}

              <Flex w={{base: "100%", md: "30%"}} direction={"column"}>
                {!loading && jobs.length <= 0 && <Flex w="100%" justify={"center"}><Text>ยังไม่มีงาน</Text></Flex>}

                {jobs.length > 0 && jobs.map((job, index) => {
                  return (
                    <Flex ml={{base:0, md: 5}} mt={{base:5, md: 0}} mb={2} key={index} w="100%">
                      <Flex direction="column" w="100%" p={1} bgColor={(index==currentJobIndex)?"blue.50":"white"}>
                        <Flex>
                          <Image
                            mr={1}
                            boxSize='25px'
                            objectFit='cover'
                            src={`${process.env.REACT_APP_API_URL}/images/${job.thumbnail}`}
                          />
                          <Link target="#" onClick={() => { openJob(job, index); }}>
                            <Text fontSize="sm" color={"navy"} mb={1}>
                              {job.media_title}{" "}
                            </Text>
                          </Link>
                        </Flex>
                        <Flex align={"center"} justify={"space-between"}>
                          <Text fontSize="xs" color="gray.400" fontWeight="400">
                            {job.job_type == "exercise" ? "แบบฝึกหัด" : "สื่อการสอน"}{" "}
                          </Text>
                          {viewType == "admin" && (
                            <Flex>
                              <IconButton
                                isDisabled={index == 0 ? true : false}
                                size="xs"
                                mr={1}
                                icon={<FaArrowUp />}
                                onClick={() => {
                                  if (index > 0) {
                                    const prevJob = jobs[index - 1];
                                    swapJobOrderByIDs(job.id, prevJob.id, job.order_num, prevJob.order_num);
                                  }
                                }}
                              />
                              <IconButton
                                isDisabled={jobs.length - 1 == index ? true : false}
                                size="xs"
                                mr={1}
                                icon={<FaArrowDown />}
                                onClick={() => {
                                  if (index < jobs.length - 1) {
                                    const nextJob = jobs[index + 1];
                                    swapJobOrderByIDs(job.id, nextJob.id, job.order_num, nextJob.order_num);
                                  }
                                }}
                              />
                              <IconButton
                                size="xs"
                                mr={1}
                                icon={<FaPencilAlt />}
                                onClick={() => handleJobEdit(job)}
                              />
                              <IconButton
                                size="xs"
                                icon={<FaTrashAlt />}
                                onClick={() => deleteJobConfirm(job.media_title, job.id)}
                              />
                            </Flex>
                          )}
                        </Flex>
                        <HSeparator my={1} />
                      </Flex>
                    </Flex>
                  );
                })}

              </Flex>
            </Flex>}
          </CardBody>
        </Card>
      </Flex>
      <ManageJobModal
        isOpen={isOpen}
        onClose={onJobSaved}
        operation={operation}
        editJob={editJob}
        activityID={activityID} />

      <LoadingModal isOpen={isOpenLoadingModal} onClose={onCloseLoadingModal} />     
    </Flex>
  )
}

export default MediaList;
