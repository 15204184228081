import React, { useEffect, useState } from 'react';
import { Box, Button, Collapse, Flex, Icon, IconButton, Input, ModalFooter, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Select, Spinner, Image, useToast, CloseButton, VStack } from '@chakra-ui/react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md'; // Import icons for expand and collapse
import { HSeparator } from 'components/Separator/Separator';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { IoAddCircle, IoBookOutline, IoHelpCircle, IoPencil, IoRemoveCircle, IoTrashBin, IoTrashBinOutline } from 'react-icons/io5';
import GradeLevelSelectionCard from './widgets/GradeLevelSelectionCard';
import SubjectSelectionCard from './widgets/SubjectSelectionCard';
import { EditIcon } from '@chakra-ui/icons';
import { FaArrowRight, FaEye, FaPencilAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { gradeBookList } from 'variables/general';
import { AiOutlineSearch } from 'react-icons/ai';
import { loadSubjects } from 'util/subjectAPI';
import YearSelectionCard from './widgets/YearSelectionCard';
import moment from 'moment';
import { getSubjects } from 'apis/subjectAPI';
import { listBookBySubjectID } from 'util/subjectAPI';
import SubjectModal from './modals/SubjectModal';
import { showWarningDialog } from 'util/alertUtil';
import { showErrorDialog } from 'util/alertUtil';
import Swal from 'sweetalert2';
import axios from 'axios';
import { showSuccessDialog } from 'util/alertUtil';
import LessonModal from './modals/LessonModal';
import GradeBookModal from './modals/GradeBookModal';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'auth/AuthProvider';
import Breadcrumbs from './widgets/Breadcrumbs';

const ClassModelAdmin = () => {
    const [openIndex, setOpenIndex] = useState(0);
    const borderColor = useColorModeValue("gray.300", "gray.600");
    const bgColor = useColorModeValue("white", "blue.600");
    const secondaryColor = useColorModeValue("gray.800", "white");
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAddTemplateModalOpen, setIsAddTemplateModalOpen] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const [yearFilter, setSelectedYears] = useState([]);
    const [subjectFilter, setSelectedSubjectFilterIDs] = useState([]);
    const [classroomId, setClassroomId] = useState('');

    const [subjects, setSubjects] = useState([]);
    const [opt, setOpt] = useState('insert')
    const [selectedSubjectID, setSelectedSubjectID] = useState(null)
    const [selectedBookID, setSelectedBookID] = useState(null)
    const [selectedLesson, setSelectedLesson] = useState(null)
    const [selectedSubjectModelID, setSelectedSubjectModelID] = useState(null)

    const [isLessonModalOpen, setLessonModalOpen] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { user, role, login, logout, isUserExist } = useAuth();

    const breadcumLinks = [
        { url: `/#/user/classroom?cid=${searchParams.get('classroom_id')}&cname=${searchParams.get('cname')}&joincode=${searchParams.get('joincode')}`, text: `${searchParams.get('cname')}` },
        { url: undefined, text: `รายชื่อบทเรียน` }
    ];

    const handleOpenLessonModal = () => {
      setLessonModalOpen(true);
    };
  
    const handleCloseLessonModal = () => {
      setLessonModalOpen(false);
    };

    const handleLessonSaved = () => {
        loadSubjectArray()
    }

    // Define dataArray internally
    /*const subjects = [
        {
            "id": 99,
            "book_id": 33,
            "book_title": "ชีววิทยา เพิ่มเติม เล่ม 3",
            "term": "2",
            "year": 2024,
            "subject_id": 5,
            "subjectThai": "ชีววิทยา",
            "subjectEnglish": "BIOLOGY",
            "lessons": [
              {
                "id": 1774,
                "lesson_img": "exam_img_1703703206467.png",
                "lesson_name": "บทที่ 13 ระบบย่อยอาหาร",
                "status": "draft",
                "ipst_class_id": 12,
                "class_name": "ชั้นมัธยมศึกษาปีที่ 5",
                "ipst_subject_model_id": 99,
                "activity_group_num": 2,
                "email": "test@gmail.com"
              }
            ]
        }
    ];*/

    const [subjectArray, setSubjectArray] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSubjects, setFilteredSubjects] = useState([]);

    const openModal = (lesson) => {
        setSelectedLesson(lesson)
        setIsModalOpen(true)
    };
    const closeModal = () => setIsModalOpen(false);

    const openAddTemplateModal = () => setIsAddTemplateModalOpen(true);
    const closeAddTemplateModal = () => setIsAddTemplateModalOpen(false);

    const toggleCollapse = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const loadSubjectInfo = async () => {
        const subs = await getSubjects()
        setSubjects(subs)
    }

    const loadSubjectArray = async () => {
        const cid = searchParams.get('classroom_id') ?? ""
        setClassroomId(cid)

        setLoading(true)
        const subs = await loadSubjects(yearFilter, subjectFilter, cid)
        console.log(`subs: ${JSON.stringify(subs)}`)
        setSubjectArray(subs)
        setLoading(false)
    }

    useEffect(() => {
        loadSubjectInfo();
      },[])

    useEffect(()=>{
        loadSubjectArray()
    },[subjectFilter, yearFilter])

    /*useEffect(() => {
        if (searchQuery.trim() === '') {
            console.log(JSON.stringify(subjectArray))
            setFilteredSubjects(subjectArray);
        } else {
            const filtered = subjectArray.filter(item =>
                item.lessons.some(lesson =>
                    lesson.email.toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
            setFilteredSubjects(filtered);
        }
    }, [searchQuery, subjectArray]);*/

    useEffect(() => {
        if (searchQuery.trim() === '') {
            console.log(JSON.stringify(subjectArray))
            setFilteredSubjects(subjectArray);
        } else {
            const filtered = subjectArray.filter(item =>
                item.lessons.some(lesson =>
                    (lesson.email?.toLowerCase() ?? '').includes(searchQuery.toLowerCase())
                )
            );
            setFilteredSubjects(filtered);
        }
    }, [searchQuery, subjectArray]);

    const deleteLessonConfirm = (lesson_id, lesson_name) => {
        Swal.fire({
          title: "กรุณายืนยัน?",
          text: `คุณต้องการลบ ${lesson_name} ใช่หรือไม่`,
          showCancelButton: true,
          confirmButtonText: 'ใช่',
          cancelButtonText: 'ยกเลิก',
          denyButtonColor: "#6CB4EE",
          confirmButtonColor: "#ED0A3F",
        }).then((result) => {
          if (result.isConfirmed) {
            deleteLesson(lesson_id);
          }
        });
      };
    
      const deleteLesson = (lesson_id) => {
        axios.post(`${process.env.REACT_APP_API_URL}/model_subject/delete_lesson`, {
            lesson_id
          }, {
            withCredentials: true
          })
          .then((response) => {
            if (response.data.status === "success") {
              loadSubjectArray();
              showSuccessDialog("สำเร็จ!", `ลบเรียบร้อย!`, 'success');
            } else {
              showWarningDialog("Error!", `ไม่สามารถลบได้!`, 'warning');
            }
          })
          .catch((error) => {
            showWarningDialog("Error!", 'Cannot connect to the server!', 'warning');
          });
      };

const checkSubjectExist = async (subject_model_id, book_image, book_id, subject_id, opt) => {
    console.log(`subject_id: ${subject_id}`)
    console.log(`book_id: ${book_id}`)

    if (opt === 'insert') {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/model_subject_new/check_subject_exist`, {
          subject_id: subject_id,
          book_id: book_id
        }, { withCredentials: true });
  
        if (response.data && response.data.status === 'success') {
          if (response.data.subject_num <= 0 && response.data.user_subject_num <= 0) {
            saveSubjectInfo(book_image, null, subject_id, book_id, opt);
          } else if (response.data.subject_num > 0 && response.data.is_add_new_user_subject_model === 1) {
            loadSubjectArray();
          } else {
            Swal.fire('Warning!', 'บทเรียนนี้มีอยู่แล้ว!', 'warning');
          }
        }
      } catch (error) {
        Swal.fire('Error (code 10)!', `${error}`, 'warning');
      }
    } else {
      checkSubjectEditable(subject_model_id, book_image, subject_id, book_id, opt);
    }
  };

    const checkSubjectEditable = async (subject_model_id, book_image, subject_id, book_id, opt) => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/model_subject/check_subject_deletable`, { subject_id: subject_model_id }, { withCredentials: true });
      
          if (response.data.status === 'success') {
            const use_num = response.data.use_num;
            if (use_num > 0) {
              showWarningDialog("ไม่สามารถแก้ไขได้!", "สาเหตุเนื่องจากมีคนอื่นอ้างถึงรายวิชานี้อยู่");
            } else {
              saveSubjectInfo(book_image, subject_model_id, subject_id, book_id, opt);
            }
          } else {
            showErrorDialog("Error!", "ไม่สามารถแก้ไขได้!");
          }
        } catch (error) {
            showErrorDialog("Error!", `${error}`);
        }
      };

    const saveSubjectInfo = async (book_image, selected_model_id, subject_id, book_id, opt) => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/model_subject_new/save_subject_info`, {
            opt,
            selected_id: selected_model_id,
            subject_id,
            book_id,
            book_image
          }, { withCredentials: true });
      
          if (response.data && response.data.status === 'success') {
            loadSubjectArray();
            closeAddTemplateModal();
            //setIsAddTemplateModalOpen(true)
      
            Swal.fire({
              title: 'Success!',
              text: 'บันทึกข้อมูลเรียบร้อย',
              icon: 'success',
              padding: '2em'
            });
          }
        } catch (error) {
          Swal.fire({
            title: 'Error! (code 11)',
            text: `${error}`,
            icon: 'warning',
            padding: '2em'
          });
        }
      }

    const handleSaveSubjectModal = (subjectID, bookID) => {
        setSelectedSubjectID(subjectID)
        setSelectedBookID(bookID)
        checkSubjectExist(selectedSubjectModelID, '', bookID, subjectID, opt)
    }

    const deleteSubjectConfirm = (subject_id, subjectTitle, bookTitle) => {
        Swal.fire({
            title: "ยืนยันการลบ",
            text: `คุณต้องการลบรายวิชา ${subjectTitle}, เล่ม ${bookTitle} ใช่หรือไม่`,
            showCancelButton: true,
            confirmButtonText: 'ใช่',
            denyButtonText: `ยกเลิก`,
            denyButtonColor: "#6CB4EE",
            confirmButtonColor: "#ED0A3F"
        }).then((result) => {
            if (result.isConfirmed) {
                checkSubjectDeletable(subject_id)
            }
        })
    }

    function checkSubjectDeletable(subject_id) {
        if(role != "TEACHER"){
            axios.post(`${process.env.REACT_APP_API_URL}/model_subject/check_subject_deletable`, { subject_id }, { withCredentials: true })
                .then(res => {
                    if (res.data.status === "success") {
                        var use_num = res.data.use_num;
                        if (use_num > 0) {
                            showWarningDialog("ไม่สามารถลบได้!", "สาเหตุเนื่องจากมีคนอื่นอ้างถึงบทเรียนนี้อยู่");
                        } else {
                            deleteSubject(subject_id);
                        }
                    } else {
                        showWarningDialog("Error!", "ไม่สามารถลบได้!");
                    }
                })
                .catch(error => {
                    showWarningDialog("Error!", `${error}`);
                });
        }
        else{
            //alert('a')
            deleteSubject(subject_id);
        }
    }

    function deleteSubject(subject_id) {
        axios.post(`${process.env.REACT_APP_API_URL}/model_subject/delete_subject`, { subject_id }, { withCredentials: true })
            .then(res => {
                if (res.data.status === "success") {
                    loadSubjectArray()
                    showSuccessDialog("สำเร็จ!", "ลบเรียบร้อย!");
                } else {
                    showWarningDialog("Error!", "ไม่สามารถลบได้!");
                }
            })
            .catch(error => {
                showWarningDialog("Error!", 'Cannot connect to the server!');
            });
    }

    const handleSearchInputChange = (event) => {
        const val = event.target.value
        console.log(val)
        setSearchQuery(val);
    };

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '100px' }}>
            {searchParams.get('classroom_id') && <Breadcrumbs links={breadcumLinks} />}
            <GradeBookModal lessonName={selectedLesson ? selectedLesson.lesson_name: ""} isOpen={isModalOpen} onClose={closeModal} data={gradeBookList} />
            <LessonModal isOpen={isLessonModalOpen} onClose={handleCloseLessonModal} 
                handleLessonSaved={handleLessonSaved}
                mode={selectedLesson != null ? "edit": "insert"}
                defaultSubjectModelID={selectedSubjectModelID}
                defaultLessonID={selectedLesson != null ? selectedLesson.id: ""}
                defaultLevel={selectedLesson != null ? selectedLesson.ipst_class_id: ""}
                defaultLessonName={selectedLesson != null ? selectedLesson.lesson_name: ""}
                defaultImage={selectedLesson != null ? `${process.env.REACT_APP_API_URL}/images/${selectedLesson.lesson_img}`: ""} />

            <SubjectModal 
                selectedBookID={selectedBookID}
                selectedSubjectID={selectedSubjectID}
                handleSaveSubjectModal={handleSaveSubjectModal}
                subjects={subjects} 
                isOpen={isAddTemplateModalOpen} 
                onClose={closeAddTemplateModal} />
            <Flex w="100%">
                <Flex w={role !== "STUDENT"? "80%":"100%"} direction={"column"} mr={5}>
                    <Card>
                        <CardHeader>
                            {role === "STUDENT" && <Text>{searchParams.get('cname') ?? ""}</Text>}
                            {role !== "STUDENT" && <Flex justify={"space-between"}>
                                <VStack dir='column' spacing={0} justify={"start"} align={"start"}>
                                    <Text>รายการต้นแบบรายวิชา</Text>
                                    {filteredSubjects && <Text fontSize={"sm"}>จำนวน {filteredSubjects.length} รายการ</Text>}
                                </VStack>
                                <Flex>
                                    {(role.toLowerCase().includes("admin")) &&<Flex align="center">
                                        <Icon as={AiOutlineSearch} color="black" w="20px" h="20px" me="8px" />
                                        <Input
                                            _placeholder={{ color: 'gray' }}
                                            placeholder="ค้นหาอีเมล.."
                                            onChange={handleSearchInputChange}
                                            border={{}}
                                            _hover={{}}
                                            _focus={{}}
                                            color="black"
                                        />
                                    </Flex>}
                                    {!classroomId && <Button onClick={()=>{
                                        setOpt('insert')
                                        setSelectedSubjectModelID(null)
                                        setSelectedSubjectID(null)
                                        setSelectedBookID(null)
                                        openAddTemplateModal()
                                    }} leftIcon={<IoAddCircle />} colorScheme='blue' variant='outline'>เพิ่มต้นแบบรายวิชา</Button>}
                                </Flex>
                            </Flex>}
                            <HSeparator mt="5px" mb="5px" />
                        </CardHeader>
                        <CardBody>
                            {!isLoading && filteredSubjects.map((item, index) => (
                                <Box key={index} mb={1} borderRadius="lg" overflow="hidden">
                                    <Flex
                                        onClick={() => toggleCollapse(index)}
                                        bg={openIndex === index ? 'blue.600' : 'blue.600'}
                                        color="white"
                                        fontWeight={400}
                                        fontSize={18}
                                        borderTopLeftRadius="5px"
                                        borderTopRightRadius="5px"
                                        cursor="pointer"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box>
                                            <Flex>
                                                {/*<Text p={4} bgColor="blue.500" mr={2}>{item.year + 543}</Text>*/}
                                                <Text p={4} bgColor="blue.500" mr={2}></Text>
                                                <Text p={4}>{item.book_title}</Text>
                                            </Flex>
                                        </Box>
                                        <Flex>
                                            <Text mr={5} fontWeight={400} fontSize={14}>{item.subjectThai}</Text>
                                            <Icon mr={2} as={openIndex === index ? MdExpandLess : MdExpandMore} fontSize="20px" />
                                        </Flex>
                                    </Flex>
                                    <Collapse in={openIndex === index} style={{ width: '100%' }}>
                                        <Box overflowX="scroll" bg={bgColor} p={4}>
                                            <Box overflowX="scroll" className="custom-scrollbar">
                                                <Table mb="10px" variant="simple" minW="800px">
                                                    <Thead>
                                                        <Tr>
                                                            <Th
                                                                w="20%"
                                                                borderColor={borderColor}
                                                                color="gray.400"
                                                                fontSize="sm"
                                                                fontWeight="normal"
                                                                px="0px"
                                                            >
                                                                <Text className='default'>รายชื่อบทเรียน</Text>
                                                                <Text fontSize={"xs"} className='default'>{item.lessons.length} บท</Text>
                                                            </Th>
                                                            <Th
                                                                px={0}
                                                                w="5%"
                                                                textAlign={"center"}
                                                                borderColor={borderColor}
                                                                color="gray.400"
                                                                fontSize="sm"
                                                                fontWeight="normal"
                                                            >
                                                                <Text className='default'>จำนวน</Text>
                                                                <Text className='default'>ชุดกิจกรรม</Text>
                                                            </Th>
                                                            {role.toLowerCase().includes("admin") && <Th
                                                                px={0}
                                                                w="5%"
                                                                textAlign={"center"}
                                                                borderColor={borderColor}
                                                                color="gray.400"
                                                                fontSize="sm"
                                                                fontWeight="normal"
                                                            >
                                                                <Text className='default'>{"email ผู้สร้าง"}</Text>
                                                            </Th>}
                                                            <Th
                                                                px={0}
                                                                w="5%"
                                                                textAlign={"center"}
                                                                borderColor={borderColor}
                                                                color="gray.400"
                                                                fontSize="sm"
                                                                fontWeight="normal"
                                                            >
                                                                <Text className='default'>วันที่สร้าง</Text>
                                                            </Th>
                                                            {role !== "STUDENT" && <Th
                                                                px={0}
                                                                w="1%"
                                                                textAlign={"center"}
                                                                borderColor={borderColor}
                                                                color="gray.400"
                                                                fontSize="sm"
                                                                fontWeight="normal"
                                                            >
                                                                <Text px={0}>ลบ</Text>
                                                            </Th>}
                                                            {role !== "STUDENT" && <Th
                                                                px={0}
                                                                w="1%"
                                                                textAlign={"center"}
                                                                borderColor={borderColor}
                                                                color="gray.400"
                                                                fontSize="sm"
                                                                fontWeight="normal"
                                                            >
                                                                <Text className='default'>แก้ไข</Text>
                                                                
                                                            </Th>}
                                                            {/*role !== "STUDENT" && <Th
                                                                px={0}
                                                                w="1%"
                                                                textAlign={"center"}
                                                                borderColor={borderColor}
                                                                color="gray.400"
                                                                fontSize="sm"
                                                                fontWeight="normal"                                                                
                                                            >
                                                                <Text className='default'>มุมมอง</Text>
                                                                <Text className='default'>นักเรียน</Text>
                                                            </Th>*/}
                                                            <Th
                                                                px={0}
                                                                w="1%"
                                                                textAlign={"center"}
                                                                borderColor={borderColor}
                                                                color="gray.400"
                                                                fontSize="sm"
                                                                fontWeight="normal"
                                                            >
                                                                <Text className='default'>เข้าดู</Text>
                                                            </Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {(item.lessons).map((lesson, lessonIndex) => (
                                                            <Tr key={lessonIndex} overflowX={"scroll"}>
                                                                <Td
                                                                    borderColor={borderColor}
                                                                >
                                                                    <Flex>
                                                                        <Image mr={2} mt={1} boxSize='30px' objectFit='contain' src={`${process.env.REACT_APP_API_URL}/images/${lesson.lesson_img}`} />
                                                                        <Flex direction={"column"}>
                                                                            <Text
                                                                                color={secondaryColor}
                                                                                fontWeight="normal"
                                                                                fontSize="md"
                                                                            >
                                                                                {lesson.lesson_name}
                                                                            </Text>
                                                                            <Text
                                                                                color={secondaryColor}
                                                                                fontWeight="200"
                                                                                fontSize="xs"
                                                                            >
                                                                                {lesson.class_name}
                                                                            </Text>
                                                                        </Flex>
                                                                        
                                                                    </Flex>
                                                                </Td>
                                                                <Td px={0} maxW="60px" w="60px" borderColor={borderColor} textAlign={"center"}>
                                                                    <Text
                                                                        px={0}
                                                                        color={secondaryColor}
                                                                        fontWeight="normal"
                                                                        fontSize="md"
                                                                    >
                                                                        {lesson.activity_group_num}
                                                                    </Text>
                                                                </Td>
                                                                {role.toLowerCase().includes("admin") && <Td
                                                                    px={0}
                                                                    textAlign={"center"}
                                                                    borderColor={borderColor}
                                                                >
                                                                    {!classroomId && <Text>{lesson.email}</Text>}
                                                                </Td>}
                                                                <Td
                                                                    px={0}
                                                                    textAlign={"center"}
                                                                    borderColor={borderColor}
                                                                >
                                                                    <Text fontSize={"xs"}>{moment(item.create_datetime).format("YYYY-MM-DD")}</Text>
                                                                </Td>
                                                                {role !== "STUDENT" && <Td
                                                                    px={0}
                                                                    textAlign={"center"}
                                                                    borderColor={borderColor}
                                                                >
                                                                     <IconButton color={"red"} ml={1} icon={<IoTrashBinOutline />} onClick={() => deleteLessonConfirm(lesson.id, lesson.lesson_name)} />
                                                                </Td>}
                                                                {role !== "STUDENT" && <Td px={0} textAlign={"center"} borderColor={borderColor}>
                                                                    <IconButton color={"brand"} mr={1} onClick={()=>{
                                                                        setSelectedLesson(lesson)
                                                                        setSelectedSubjectModelID(item.id)
                                                                        handleOpenLessonModal()
                                                                    }} icon={<EditIcon />} />
                                                                </Td>}
                                                                {/*role !== "STUDENT" && <Td px={0} textAlign={"center"} borderColor={borderColor}>
                                                                    <IconButton onClick={() => { 
                                                                        const cid = searchParams.get('classroom_id') ?? ""
                                                                        history.push(`activity-list?subject_id=${item.id}&lesson_id=${lesson.id}&lesson=${lesson.lesson_name}&view=student&classroom_id=${cid}&cname=${searchParams.get("cname")}`) 
                                                                    }} color={"blue"} icon={<FaEye />} />
                                                                </Td>*/}
                                                                <Td px={0} textAlign={"center"} borderColor={borderColor}>
                                                                    <IconButton onClick={() => { 
                                                                        const cid = searchParams.get('classroom_id') ?? ""
                                                                        history.push(`activity-list?subject_id=${item.id}&lesson_id=${lesson.id}&lesson=${lesson.lesson_name}&classroom_id=${cid}&cname=${searchParams.get("cname")}`) 
                                                                    }} color={"green"} icon={<FaEye />} />
                                                                </Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                                {role !== "STUDENT" && <Flex justify={"end"} pb={4} pr={1} w="100%">
                                                    <Button mr={1} fontWeight={400} leftIcon={<IoAddCircle color='navy' />} variant='ghost' onClick={()=>{
                                                        setSelectedLesson(null)
                                                        setSelectedSubjectModelID(item.id)
                                                        handleOpenLessonModal()
                                                    }}>เพิ่มบทเรียน</Button>
                                                    <Button mr={1} fontWeight={400} leftIcon={<IoRemoveCircle color='red' />} variant='ghost' onClick={()=>{
                                                        deleteSubjectConfirm(item.id, item.subjectThai, item.book_title)
                                                    }}>ลบรายวิชา</Button>
                                                    <Button fontWeight={400} leftIcon={<FaPencilAlt color='teal' />} variant='ghost' onClick={()=>{
                                                        setOpt('edit')
                                                        setSelectedSubjectModelID(item.id)
                                                        setSelectedSubjectID(item.subject_id)
                                                        setSelectedBookID(item.book_id)
                                                        openAddTemplateModal()
                                                    }}>แก้ไขรายวิชา</Button>
                                                </Flex>}
                                            </Box>
                                        </Box>
                                    </Collapse>
                                </Box>
                            ))}
                            {!isLoading && filteredSubjects.length == 0 && <Flex mt={10} justify={"center"}><Text>ไม่มีข้อมูล</Text></Flex>}
                            {isLoading && <Flex mt={10} justify={"center"}><Spinner mr={1}/>กำลังดึงข้อมูล...</Flex>}
                        </CardBody>
                    </Card>
                </Flex>
                {role !== "STUDENT" && <Flex w="20%" bgColor="white" overflow={"hidden"}>
                    <Stack w="100%" direction="column" spacing="24px">
                        <SubjectSelectionCard setSelectedSubjectIDs={setSelectedSubjectFilterIDs} />
                        {/*<YearSelectionCard setSelectedYears={setSelectedYears} />*/}
                    </Stack>
                </Flex>}
            </Flex>
        </Flex>
    );
};

export default ClassModelAdmin;
