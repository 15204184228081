import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Stack,
  Box,
  ModalFooter,
  VStack,
  Divider,
  Flex,
  toast,
} from "@chakra-ui/react";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import Swal from "sweetalert2";
import { API_URL } from "variables/constant";
import { getParam } from "util/common";
import axios from "axios";
import QuestionRenderer from "./QuestionRenderer"; // Import the new component

const QuestionPreviewModal = ({ onImportSuccess, isOpen, onClose, questionData, deleteQuestionIDAfterImport=undefined}) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [oldOrder, setOldOrder] = useState();

  const transformTFtoMC = (data) => {
    const fixedTestset = data.ResponseFixedRandomTestset;
    if (!fixedTestset) return data;

    const testsetGroup =
      fixedTestset.ResponseTestsetGroup_ResponseFixedRandomTestset?.ResponseTestsetGroup;
    if (!testsetGroup) return data;

    const itemGroupWrapper = testsetGroup.ResponseItemGroup_ResponseTestsetGroup;
    if (!itemGroupWrapper || !itemGroupWrapper.ResponseItemGroup) return data;

    // Make sure we are working with an array
    let items = itemGroupWrapper.ResponseItemGroup;
    if (!Array.isArray(items)) {
      items = [items];
    }

    // We will build a new items array (flat mapping any TF question with subquestions)
    let newItems = [];
    items.forEach((item) => {
      // Check if item is a TF question with subquestions to split
      if (item.QuestionType === 'TF' && item.ItemTFSubquestion_ResponseItemGroup) {
        let subquestions = item.ItemTFSubquestion_ResponseItemGroup.ItemTFSubquestion;
        if (!Array.isArray(subquestions)) {
          subquestions = [subquestions];
        }
        // For each subquestion, create a new question item
        subquestions.forEach((sub) => {
          // Create a copy of the original item
          const newItem = { ...item };

          // Remove the TF subquestion container
          delete newItem.ItemTFSubquestion_ResponseItemGroup;

          // Change question type to MC
          newItem.QuestionType = 'MC';

          // Concatenate the parent ItemQuestion with the subquestion text.
          // You can modify the separator as needed.
          newItem.ItemQuestion = `${item.ItemQuestion ? item.ItemQuestion + ' <br/><br/> ' : ''}${sub.ItemSubQuestion}`;

          // Get the TF choices for this subquestion, ensuring it is an array.
          let tfChoices = sub.ItemTFChoice_ItemTFSubquestion?.ItemTFChoice;
          if (tfChoices) {
            if (!Array.isArray(tfChoices)) {
              tfChoices = [tfChoices];
            }
            newItem.ItemChoice_ResponseItemGroup = { ItemChoice: tfChoices };
          } else {
            newItem.ItemChoice_ResponseItemGroup = { ItemChoice: [] };
          }

          // Optionally update ItemID (for example, by appending the subquestion number)
          newItem.ItemID = `${item.ItemID}_${sub.ItemNo}`;

          // Push the new item into the newItems array
          newItems.push(newItem);
        });
      } else {
        // For non-TF items (or TF items without subquestions), ensure the choices are in an array
        if (item.QuestionType === 'MC' && item.ItemChoice_ResponseItemGroup) {
          let choices = item.ItemChoice_ResponseItemGroup.ItemChoice;
          if (!Array.isArray(choices)) {
            item.ItemChoice_ResponseItemGroup.ItemChoice = [choices];
          }
        }
        newItems.push(item);
      }
    });

    // Update the items array with the new set of items
    itemGroupWrapper.ResponseItemGroup = newItems;

    // Update total item counts
    testsetGroup.TotalItems = newItems.length;
    fixedTestset.TestSetTotalItem = newItems.length;

    // Optionally, update ItemSeq to be sequential (starting at 1)
    newItems.forEach((item, index) => {
      item.ItemSeq = index + 1;
    });

    return data;
  };

  useEffect(() => {

    if (isOpen) {
      //console.log(`questionData: ${JSON.stringify(questionData)}`)

      //transform TF to MC if applicable
      questionData = transformTFtoMC(questionData)

      if(questionData.question_order_num){
        setOldOrder(questionData.question_order_num)
      }
      const fetchedQuestions = questionData.ResponseFixedRandomTestset.ResponseTestsetGroup_ResponseFixedRandomTestset.ResponseTestsetGroup.ResponseItemGroup_ResponseTestsetGroup.ResponseItemGroup || [];
      if (Array.isArray(fetchedQuestions)) {
        setQuestions(fetchedQuestions);
      } else {
        setQuestions([fetchedQuestions]);
      }
      setCurrentQuestionIndex(0);
    }
  }, [isOpen, questionData]);

  const currentQuestion = questions.length > 0 ? questions[currentQuestionIndex] : null;

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  function extractImageUrls(text) {
    const urlRegex = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif|svg))/gi;
    return text.match(urlRegex) || [];
  }

  function extractImageUrlsFromArray(textArray) {
    const allImageUrls = textArray.flatMap(text => extractImageUrls(text));
    return allImageUrls;
  }

  const deleteQuestion = async (question_id) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/questions/delete_question`, { question_id }, { withCredentials: true });
    if (response.data.status !== 'success') {
       showWarningToast(toast, "อัพเดทไม่สำเร็จ", `ไม่สามารถลบข้อเก่าได้ กรุณาลองใหม่อีกครั้ง`);
    } 
  };

  const uploadImages = async (imageUrls) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/questions/download_all_images`, { images: imageUrls }, { withCredentials: true });
  };

  const uploadImportedQuestions = async (upload_questions) => {
    var question_id = "";
    const exam_id = getParam("eid");

    if (!exam_id) {
      Swal.fire("Warning", "Wrong input link", "warning");
      return;
    }

    if (upload_questions.length > 0) {
      var question = upload_questions[0];
      upload_questions = upload_questions.slice(1);
      var q = question["ItemQuestion"];

      var operation = "add";
      var question_type = question.QuestionType;
      var content = '';
      var source = 'import';
      var otim_item_id = question["ItemID"];
      var test_set_id = question["TestSetID"];
      var ansArray = [];
      var correctArray = [];
      var imgArray = [];
      var sequenceArray = [];
      var matching_items = {};
      var images = [];
      var explanation = question["Explanation"];
      var modifiedDate = question["LastModifiedDate"] ?? ""

      if (q) {
        images = extractImageUrls(q);
        content = q.replace("<img ", "<img crossorigin=\"anonymous\" ");
        content = content.replace(/http.+images/, "/images");
      }

      if (question_type === "MC") {
        question_type = "choice";
      } else if (question_type === "SA") {
        question_type = "fill";
      } else if (question_type === "MA") {
        question_type = "matching";
      }

      var cg = question["ItemChoice_ResponseItemGroup"];
      if (cg !== undefined) {
        var choices = cg["ItemChoice"];
        var i = 0;

        while (i < choices.length) {
          var answer = choices[i]["ItemChoiceKey"];
          ansArray.push(choices[i]["ItemChoice"]);
          imgArray.push('');
          sequenceArray.push(choices[i]["ItemChoiceSeq"]);

          if (answer === "True") {
            correctArray.push(1);
          } else {
            correctArray.push(0);
          }
          i += 1;
        }
      }

      if (question_type === "fill") {
        var cg = question["ItemShortAnswer_ResponseItemGroup"];

        if (cg !== undefined) {
          var shortAnswers = cg["ItemShortAnswer"];
          var i = 0;

          while (i < shortAnswers.length) {
            ansArray.push(shortAnswers[i]["ItemAnswer"]);
            correctArray.push(1);
            imgArray.push('');
            sequenceArray.push(1);

            i += 1;
          }
        }
      }

      if (question_type === "matching") {
        var iq = question["ItemMatchingQuestion_ResponseItemGroup"];
        var ic = question["ItemMatchingChoice_ResponseItemGroup"];

        matching_items = { ItemMatchingQuestion: iq.ItemMatchingQuestion, ItemMatchingChoice: ic.ItemMatchingChoice };
      }

      if (question_type === "choice" || question_type === "fill" || question_type === "matching") {
        const ansImgs = extractImageUrlsFromArray(ansArray);
        const questionAndAnswerImgs = [...images, ...ansImgs];
        console.log(JSON.stringify(questionAndAnswerImgs))

        if(questionAndAnswerImgs.length > 0){
          await uploadImages(questionAndAnswerImgs)
        }

        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/questions/question_insert`, {
            operation,
            question_id,
            question_type,
            exam_id,
            content,
            otim_item_id,
            test_set_id,
            imgArray: JSON.stringify(imgArray),
            ansArray: JSON.stringify(ansArray),
            correctArray: JSON.stringify(correctArray),
            sequenceArray: JSON.stringify(sequenceArray),
            matching_items: JSON.stringify(matching_items),
            explanation,
            modified_date: modifiedDate,
            source,
            order_num: oldOrder ?? null
          });

          if (response.data && response.data.status === "success") {

            if (upload_questions.length > 0) {
              uploadImportedQuestions(upload_questions);
            } else {
              if(deleteQuestionIDAfterImport){
                await deleteQuestion(deleteQuestionIDAfterImport);
              }

              Swal.fire({
                text: "ระบบได้ทำการบันทึกเรียบร้อย",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "ตกลง",
                customClass: {
                  confirmButton: "btn btn-primary"
                }
              }).then(result => {
                if (result.isConfirmed) {
                  onImportSuccess()
                }
              });
            }
          } else {
            Swal.fire("Warning", "Cannot connect to the server", "warning");
          }
        } catch (error) {
          console.log(error);
          Swal.fire("Warning", "Cannot connect to the server", "warning");
        }
      }
    }
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{deleteQuestionIDAfterImport ?"อัพเดทคำถาม": "นำเข้าคำถาม"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {currentQuestion && (
            <QuestionRenderer question={currentQuestion} />
          )}
        </ModalBody>
        <ModalFooter>
          <Flex align="center" justify="space-between" w="100%" mt={6} mr={5}>
            <Button leftIcon={<BiLeftArrow />} variant="ghost" colorScheme="blue" onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>
              ก่อนหน้า
            </Button>
            <Text color="navy">{`ข้อที่ ${currentQuestionIndex + 1}/${questions.length > 0 ? questions.length : "-"}`}</Text>
            <Button rightIcon={<BiRightArrow />} variant="ghost" colorScheme="blue" onClick={handleNextQuestion} disabled={currentQuestionIndex === questions.length - 1}>
              ถัดไป
            </Button>
          </Flex>
          <Button mt={4} variant="outline" colorScheme="blue" onClick={onClose}>
            ยกเลิก
          </Button>
          <Button isDisabled={questions.length === 0} ml={2} mt={4} colorScheme="blue" onClick={() => {
            const upload_questions = [...questions];
            uploadImportedQuestions(upload_questions);
          }}>
            
            {deleteQuestionIDAfterImport ?"อัพเดท": "นำเข้า"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default QuestionPreviewModal;