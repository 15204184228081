import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spinner, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { showWarningDialog } from "util/alertUtil";
import { listBookBySubjectID } from "util/subjectAPI";

const SubjectModal = ({ handleSaveSubjectModal, subjects, selectedSubjectID, selectedBookID, isOpen, onClose }) => {
    const [isLoading, setLoading] = useState(false)
    const [books, setBooks] = useState([]);
    const [subjectID, setSubjectID] = useState()
    const [bookID, setBookID] = useState()

    console.log(`selectedSubjectID: ${selectedSubjectID}`)
    console.log(`selectedBookID: ${selectedBookID}`)

    useEffect(() => {
        if(selectedSubjectID){
            setSubjectID(selectedSubjectID)
        }
    },[selectedSubjectID])

    useEffect(() => {
        if(selectedBookID){
            setBookID(selectedBookID)
        }
    },[selectedBookID])

    useEffect(() => {
        async function loadBookInfo(){
            if(subjectID){
                setLoading(true)
                const books = await listBookBySubjectID(subjectID)
                setLoading(false)
                setBooks(books)
            }
        }
        loadBookInfo();
    },[subjectID])

    const handleSubjectChange = (e) => {
        const val = e.target.value;
        setSubjectID(val);
        console.log(`subject id: ${val}`)
    }

    const handleBookChange = (e) => {
        const val = e.target.value;
        setBookID(val);
        console.log(`book id: ${val}`)
    }

    const handleSave = () => {
        if (!subjectID || !bookID) {
            showWarningDialog("แจ้งเตือน", "กรุณาเลือกรายวิชาและเล่มหนังสือ");
            return;
        }
        handleSaveSubjectModal(subjectID, bookID);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{selectedSubjectID?"แก้ไขรายวิชา":"เพิ่มต้นแบบรายวิชา"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack spacing={4}>
                        <Select placeholder="เลือกรายวิชา" onChange={handleSubjectChange} value={subjectID || ''}>
                            {subjects && subjects.length > 0 && subjects.map((s, index)=>{
                                return <option key={`s${s.id}`} value={s.id}>{s.subjectThai}</option>
                            })}
                        </Select>
                        {!isLoading && <Select placeholder="เลือกเล่มหนังสือ" onChange={handleBookChange} value={bookID || ''}>
                            {books.map((b, index)=>{
                                return <option key={`b${b.id}`} value={b.id}>{b.book_title}</option>
                            })}
                        </Select>}
                        {isLoading && <Flex><Spinner mr={1} /><Text>กำลังดึงข้อมูล...</Text></Flex>}
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={isLoading} colorScheme="blue" mr={3} onClick={handleSave}>บันทึก</Button>
                    <Button disabled={isLoading} variant="ghost" onClick={onClose}>ยกเลิก</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default SubjectModal;