
import React, { useRef, useState, useEffect } from 'react';
import { Flex, Box, Stack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Text } from '@chakra-ui/react';
import YouTube from 'react-youtube';
import { useHistory } from 'react-router-dom';
import { MathJax, MathJaxContext } from "better-react-mathjax";

const QuestionPreview = ({ betweenQuestions, endQuestions, youtubeID, endQuestionTime, duration }) => {
  const playerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [progressInterval, setProgressInterval] = useState(null);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [showEndQuestionsModal, setShowEndQuestionsModal] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [currentEndQuestionIndex, setCurrentEndQuestionIndex] = useState(0);
  const [endQuestionTimeSeconds, setEndQuestionTimeSeconds] = useState(duration - 5);
  const history = useHistory();

  const opts = {
    playerVars: { rel: 0, iv_load_policy: 3 }
  };

  const config = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"]
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"]
      ]
    }
  };

  useEffect(() => {
    if (endQuestionTime) {
      const secs = timeStringToSeconds(endQuestionTime)
      setEndQuestionTimeSeconds(secs)
      //alert(duration - secs)
    }
  }, [endQuestionTime]);

  useEffect(() => {
    const handleUnload = () => {
      clearInterval(progressInterval);
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [progressInterval]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      clearInterval(progressInterval);
    });

    return () => {
      unlisten();
    };
  }, [history, progressInterval]);

  const onReady = (event) => {
    playerRef.current = event.target;
  };

  const onPlay = () => {
    trackProgress();
  };

  const onStateChange = (event) => {
    if (event.data === 1) {
      console.log('Video is playing');
    } else {
      console.log('Video is paused or ended');
      clearInterval(progressInterval);
    }
  };

  const trackProgress = () => {
    const intervalId = setInterval(() => {
      const currentTime = playerRef.current.getCurrentTime();
      setCurrentTime(currentTime);

      // Check betweenQuestions
      betweenQuestions.forEach((question, index) => {
        if(question.display_time){
          const questionTimeInSeconds = Math.floor(currentTime)
          const questionTime = timeStringToSeconds(question.display_time);
          if (questionTimeInSeconds === questionTime && currentQuestionIndex !== index) {
            setCurrentQuestionIndex(index);
            setShowQuestionModal(true);
            playerRef.current.pauseVideo();
          }
        }
      });

      // Check endQuestions
      const videoDuration = playerRef.current.getDuration();
      const timeRemaining = videoDuration - currentTime;
      const timeRemainingInSeconds = Math.floor(timeRemaining)

      if (timeRemainingInSeconds == (duration - endQuestionTimeSeconds) && !showEndQuestionsModal && endQuestions.length > 0) {
        alert(endQuestions.length)
        setShowEndQuestionsModal(true);
        playerRef.current.pauseVideo();
      }

    }, 1000);

    setProgressInterval(intervalId);
  };

  const timeStringToSeconds = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const closeModal = () => {
    setShowQuestionModal(false);
    setShowEndQuestionsModal(false);
    setCurrentQuestionIndex(null);
    setCurrentEndQuestionIndex(0);
    playerRef.current.playVideo();
  };

  const nextEndQuestion = () => {
    if (currentEndQuestionIndex < endQuestions.length - 1) {
      setCurrentEndQuestionIndex(currentEndQuestionIndex + 1);
    }
  };

  const prevEndQuestion = () => {
    if (currentEndQuestionIndex > 0) {
      setCurrentEndQuestionIndex(currentEndQuestionIndex - 1);
    }
  };

  return (
    <Flex w="100%" direction="column" justify="center">
      <MathJaxContext version={3} config={config}>
        {youtubeID && (
          <Flex bgColor="gray.100" w="100%" align="center" justify="center">
            <Box align="center" w="60%" my={5}>
              <YouTube
                videoId={youtubeID}
                onReady={onReady}
                onStateChange={onStateChange}
                onPlay={onPlay}
                opts={opts}
                className="youtubeContainer"
              />
            </Box>
          </Flex>
        )}
        <Modal size="3xl" isOpen={showQuestionModal} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textStyle="h5">คำถามระหว่างวีดิทัศน์</ModalHeader>
            <ModalBody>
            {currentQuestionIndex !== null &&
              <Box mb={2}>
                <MathJax>
                  <Box dangerouslySetInnerHTML={{ __html: betweenQuestions[currentQuestionIndex].question }} w="100%" />
                </MathJax>   
              </Box>
            }

            {currentQuestionIndex !== null &&
              <Stack spacing={2}>
                {betweenQuestions[currentQuestionIndex].question_items.map((choice, index) => (
                  <MathJax>
                    <Box 
                    dangerouslySetInnerHTML={{ __html: `<span style="color: black">${choice.text}</span>` }} 
                    w="100%" 
                    key={choice.id}
                    borderWidth={1}
                    p={4}
                    rounded="md"
                    borderColor={choice.is_correct ? "green.500" : "gray.300"}
                    bg={choice.is_correct ? "green.50" : "white"}
                  />
                </MathJax> 
                ))}
                <Box w="100%" p={2} bgColor="blue.50" borderWidth="2px">
                    <Flex mb={2}>
                      <Text mr={2} fontWeight={500}>คำถามข้อที่:</Text>
                      <Text >{(currentQuestionIndex+1)}/{(betweenQuestions.length)}</Text>
                    </Flex>
                    <Flex mb={2}>
                      <Text mr={2} fontWeight={500}>ประเภทคำถาม:</Text>
                      <Text >{betweenQuestions[currentQuestionIndex].question_type=="choice"?"ตัวเลือก":"เติมคำ"}</Text>
                    </Flex>
                    <Flex mb={2}>
                      <Text mr={2} fontWeight={500}>คะแนน:</Text>
                      <Text >{betweenQuestions[currentQuestionIndex].score ?? "ไม่ระบุ (1 คะแนน)"}</Text>
                    </Flex>
                    <Flex mb={2}>
                      <Text mr={2} fontWeight={500}>เวลาที่ปรากฎ:</Text>
                      <Text >{betweenQuestions[currentQuestionIndex].display_time ?? "ไม่ระบุ"}</Text>
                    </Flex>
                    <Flex mb={2}>
                      <Text mr={2} fontWeight={500}>เวลาย้อนกลับ (กรณีต้องการดูใหม่):</Text>
                      <Text >{betweenQuestions[currentQuestionIndex].back_time ?? "ไม่ระบุ"}</Text>
                    </Flex>
                    <Flex w="100%" mb={2}>
                      <Text minW="120px" mr={2} fontWeight={500}>คำอธิบายเฉลย:</Text>
                      <Box dangerouslySetInnerHTML={{ __html: betweenQuestions[currentQuestionIndex].description }} w="100%" />
                    </Flex>
                </Box>
              </Stack>
            }
          </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" onClick={closeModal}>ปิด</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {endQuestions && endQuestions.length > 0 && (
          <Modal size={"2xl"} isOpen={showEndQuestionsModal} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader textStyle="h5">คำถามท้ายวีดิทัศน์</ModalHeader>
              <ModalBody>
                {endQuestions.map((endQuestion, index) => (
                  <Box key={index} mb={4}>
                    <Box mb={2}>
                      <MathJax>
                        <Flex>
                          <Text width={"18px"} mr={1}>{index+1}{")"}</Text>
                          <Box dangerouslySetInnerHTML={{ __html: endQuestion.question }} w="100%" />
                        </Flex>
                      </MathJax>   
                    </Box>
                    <Stack spacing={2}>
                      {endQuestion.question_items.map((choice, choiceIndex) => (
                        <MathJax key={choiceIndex}>
                          <Box 
                            dangerouslySetInnerHTML={{ __html: `<span style="color: #CCC">${choice.text}</span>` }} 
                            w="100%" 
                            borderWidth={1}
                            p={4}
                            rounded="md"
                            borderColor={choice.is_correct ? "green.500" : "gray.300"}
                            bg={choice.is_correct ? "green.50" : "white"}
                          />
                        </MathJax> 
                      ))}
                      <Box w="100%" p={2} bgColor="blue.50" borderWidth="2px">
                        {/* Additional information about the question */}
                        <Flex mb={2}>
                          <Text mr={2} fontWeight={500}>คำถามข้อที่:</Text>
                          <Text>{index + 1}/{endQuestions.length}</Text>
                        </Flex>
                        <Flex mb={2}>
                          <Text mr={2} fontWeight={500}>ประเภทคำถาม:</Text>
                          <Text>{endQuestion.question_type === "choice" ? "ตัวเลือก" : "เติมคำ"}</Text>
                        </Flex>
                        <Flex mb={2}>
                          <Text mr={2} fontWeight={500}>คะแนน:</Text>
                          <Text>{endQuestion.score ?? "ไม่ระบุ (1 คะแนน)"}</Text>
                        </Flex>
                        <Flex w="100%" mb={2}>
                          <Text minW="100px" mr={2} fontWeight={500}>คำอธิบายเฉลย:</Text>
                          <Box dangerouslySetInnerHTML={{ __html: endQuestion.description }} w="100%" />
                        </Flex>
                      </Box>
                    </Stack>
                  </Box>
                ))}
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" onClick={closeModal}>ปิด</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </MathJaxContext>
    </Flex>
  );
};

export default QuestionPreview;

