import { useAuth } from "auth/AuthProvider";
import { useGlobalState } from "hooks/globalState";
import React, { useEffect, useRef, useState } from "react";

const InactivityChecker = () => {
  const timeoutDuration = 30 * 60 * 1000; // 30 minutes
  const { logout } = useAuth(); // Assuming logout is from useAuth
  const [isLoggedOut, setIsLoggedOut] = useGlobalState("isLoggedOut");
  const timeoutRef = useRef(null);

  // Redirect the user to the login page if logged out
  useEffect(() => {
    if (isLoggedOut) {
      console.log("User is logged out, redirecting to /user");
      window.location = `/user`;
    }
  }, [isLoggedOut]);

  // Function to reset the inactivity timer
  const resetTimer = () => {
    //console.log("Activity detected, resetting timer...");
    clearTimeout(timeoutRef.current); // Clear any existing timer

    // Set a new inactivity timeout
    timeoutRef.current = setTimeout(() => {
      console.log("Inactivity timeout reached, logging out...");
      logout(); // Log out the user
      setIsLoggedOut(true); // Update the global state
    }, timeoutDuration);
  };

  // Attach and clean up event listeners
  useEffect(() => {
    const activityEvents = ["mousemove", "keydown", "mousedown", "touchstart"];

    // Attach event listeners to reset the timer on activity
    activityEvents.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    // Initialize the timer when the component mounts
    resetTimer();

    // Cleanup event listeners and clear timeout on unmount
    return () => {
      clearTimeout(timeoutRef.current);
      activityEvents.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, []);

  return null; // This component doesn't render any UI
};

export default InactivityChecker;


/*import { useAuth } from "auth/AuthProvider";
import { useGlobalState } from "hooks/globalState";
import React, { useEffect, useState } from "react";
import { showInfoDialog } from "util/alertUtil";
import { useHistory } from 'react-router-dom';

const InactivityChecker = () => {
  const history = useHistory();
  const [isInactive, setIsInactive] = useState(false);
  const timeoutDuration = 30 * 60 * 1000; // 30 minutes
  //const timeoutDuration = 5000 //1 * 60 * 1000; // 1 minute
  const { getUser, role, login, logout, isUserExist } = useAuth();
  const [isLoggedOut, setIsLoggedOut] = useGlobalState('isLoggedOut')

  useEffect(() => {
    if(isLoggedOut){
      window.location = `/user`
    }
  }, [isLoggedOut])

  useEffect(() => {
    let timeout;

    const resetTimer = () => {
      //console.log("------>")
      clearTimeout(timeout);
      setIsInactive(false);

      timeout = setTimeout(() => {
        logout();
        setIsLoggedOut(true)
        setIsInactive(true); // Set user as inactive after 30 minutes
        handleInactivity(); // Trigger action on inactivity
      }, timeoutDuration);
    };

    // Activity event listeners
    const activityEvents = ["mousemove", "keydown", "mousedown", "touchstart"];

    activityEvents.forEach((event) =>
      window.addEventListener(event, resetTimer)
    );

    resetTimer(); // Start the initial timer

    // Cleanup event listeners on component unmount
    return () => {
      clearTimeout(timeout);
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetTimer)
      );
    };
  }, []);

  const handleInactivity = () => {
    // Action to take after inactivity (e.g., logout, show alert)
    //showInfoDialog("ระบบไม่ถูกใช้งานเกิน 30 นาที", "กรุณา login เพื่อเข้าใช้งานระบบชั้นเรียนใหม่")
    //history.pushState(`/user`)
    window.location = '/user'
  };

  return (
    <></>
  );
};

export default InactivityChecker;
*/
