import React from "react";
import { Box, Divider, Stack, Text, VStack } from "@chakra-ui/react";
import { MathJax, MathJaxContext } from "better-react-mathjax";

const QuestionRenderer = ({ question }) => {
  const config = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"]
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"]
      ]
    }
  };

  const renderMatchingQuestion = (question) => (
    <Stack spacing={4}>
      <Box>
        {question.ItemMatchingQuestion_ResponseItemGroup.ItemMatchingQuestion.map((item) => (
          <Box
            key={item.ItemLeftSideSeq}
            borderWidth={1}
            p={4}
            rounded="md"
            borderColor="gray.300"
            bg="white"
            position="relative"
          >
            <MathJax>
              <Box className="preview-box" w="100%">
               <Box dangerouslySetInnerHTML={{ __html: item.ItemLeftSide }} />
              </Box>
            </MathJax>
          </Box>
        ))}
      </Box>
      <Box>
        {question.ItemMatchingChoice_ResponseItemGroup.ItemMatchingChoice.map((choice) => (
          <Box
            key={choice.ItemRightSideSeq}
            borderWidth={1}
            p={4}
            rounded="md"
            borderColor="gray.300"
            bg="white"
            position="relative"
          >
            <MathJax><Text dangerouslySetInnerHTML={{ __html: choice.ItemRightSide }} /></MathJax>
          </Box>
        ))}
      </Box>
    </Stack>
  );

  const renderFillQuestion = (question) => (
    <Stack spacing={4}>
      {question.ItemShortAnswer_ResponseItemGroup.ItemShortAnswer.map((answer) => (
        <Box key={answer.ItemAnswerSeq} borderWidth={1} p={4} rounded="md" borderColor="gray.300" bg="white">
          <Text>{answer.ItemAnswer}</Text>
        </Box>
      ))}
    </Stack>
  );

  const renderChoiceQuestion = (question) => (
    <Stack spacing={4}>
      {question.ItemChoice_ResponseItemGroup.ItemChoice.map((choice) => (
        <Box
          key={choice.ItemChoiceSeq}
          borderWidth={1}
          p={4}
          rounded="md"
          borderColor={choice.ItemChoiceKey === "True" ? "green.500" : "gray.300"}
          bg={choice.ItemChoiceKey === "True" ? "green.50" : "white"}
        >
          <MathJax>
            <Box className="preview-box" w="100%">
              <Text dangerouslySetInnerHTML={{ __html: choice.ItemChoice }} />
            </Box>
          </MathJax>
        </Box>
      ))}
    </Stack>
  );

  const renderQuestionContent = (question) => {
    //alert(question.QuestionType)
    switch (question.QuestionType) {
      case 'MA' :
        return renderMatchingQuestion(question);
      case 'SA':
        return renderFillQuestion(question);
      case 'MC':
        return renderChoiceQuestion(question);
      default:
        return null;
    }
  };

  return (
    <MathJaxContext version={3} config={config}>
      {question && (
        <Box mb={8}>
          <MathJax>
            <Box className="preview-box" w="100%">
              <Text mb={4} fontWeight={600} dangerouslySetInnerHTML={{ __html: question.ItemQuestion }} />
            </Box>
          </MathJax>
          {renderQuestionContent(question)}
          <Box mt={4} p={4} borderWidth={1} rounded="md" borderColor="gray.300" bg="gray.100">
            <Text fontSize={"18"} fontWeight={600}>คำอธิบาย</Text>
            <MathJax><Text dangerouslySetInnerHTML={{ __html: question.Explanation }} /></MathJax>
          </Box>
          {question.Indicators && (
            <>
              <Divider my={4} />
              <Text fontWeight={600}>ตัวชี้วัด:</Text>
              <VStack align="start">
                {JSON.parse(question.Indicators).map((indicator, i) => (
                  <Text key={i}>{indicator.name}</Text>
                ))}
              </VStack>
            </>
          )}
        </Box>
      )}
    </MathJaxContext>
  );
};

export default QuestionRenderer;
